// import React, { useState } from 'react';
// import profilepic from '../../assets/Images/profile-pic.png';
// import rankingpic from '../../assets/Images/ranking.png';
// import rankingstarpic from '../../assets/Images/ranking-star.png';
// import lines from '../../assets/Images/lines.png';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Button from '@mui/material/Button';
// import SkeletonChildrenDemo from './RolebasedTestimonialskeleton' ;
// import '../../assets/Css/CourseDetails.css';
 
// const ReviewCard = () => {
//   return (
//     <div className="course-testimonials-section">
//     {/* <h3 className="testimonials-heading">What Our Students Say</h3> */}
//     <div className="course-testimonial-card">  
//       <div className="course-testimonial-header">
//         <img src={profilepic} alt="Profile" className="course-testimonial-profile-img" />
//         <div className="course-testimonial-header-info">
//           <div className="course-testimonial-name">P Kiran Kumar</div>
//           <div className="course-testimonial-designation">UX Designer</div>
//         </div>
//         <div className='course-testimonial-lines'>
//           <img src={lines} alt="Icon" />
//         </div>
//       </div>
//       <div className="course-testimonial-review-text">
//         Replace any element on the template with your choice of animated texts, images, cartoon characters.
//       </div>
//       <div className="course-testimonial-footer">
//         <div className="course-testimonial-stars">
//           <img src={rankingpic} alt="Star" className="course-testimonial-star-img" />
//           <img src={rankingpic} alt="Star" className="course-testimonial-star-img" />
//           <img src={rankingpic} alt="Star" className="course-testimonial-star-img" />
//           <img src={rankingstarpic} alt="Star" className="course-testimonial-star-img" />
//           <img src={rankingstarpic} alt="Star" className="course-testimonial-star-img" />
//         </div>
//         <div className="course-testimonial-rating">3.0</div>
//         <div className="course-testimonial-review-date">Reviewed on 21-07-2024</div>
//       </div>
//     </div>
//   </div>
 
//   );
// };
 
// const ReviewCardRow = () => {
//   const [showMore, setShowMore] = useState(false);
 
//   const testimonials = [
//     <ReviewCard key={1} />,
//     <ReviewCard key={2} />,
//     <ReviewCard key={3} />,
//     <ReviewCard key={4} />,
//     <ReviewCard key={5} />,
//     // Add more testimonials as needed
//   ];
 
//   return (
//     <div>
//       <h4 className="testimonials-heading">What Our Students Say</h4>
//       <div className="course-testimonial-row">
//         {testimonials.slice(0, 3)}
//       </div>
//       {showMore && (
//         <div className="course-testimonial-row">
//           {testimonials.slice(3)}
//         </div>
//       )}
//       <div className="course-details-see-more-container">
//         <Button onClick={() => setShowMore(!showMore)} className="course-details-see-more-button">
//           {showMore ? 'less' : 'More'}
//           <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
//         </Button>
//       </div>
//     </div>
//   );
// };
 
// export default ReviewCardRow;




import React, { useState, useEffect,  useRef} from 'react';
import profileAvatar from '../../assets/Images/profileAvatar.png';
import rankingpic from '../../assets/Images/ranking.png';
import rankingstarpic from '../../assets/Images/ranking-star.png';
import lines from '../../assets/Images/lines.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import '../../assets/Css/CourseDetails.css';
import { adminaxios } from '../../axios';
import TestimonialSkeleton from '../CourseDetails/CourseDetailsTestimonialsSkeleton';

const ReviewCard = ({ review }) => {
  const { comment, rating, date, posted_by, user_role } = review;
  const [showFullText, setShowFullText] = useState(true); 
    const [showSeeMoreButton, setShowSeeMoreButton] = useState(false); // To show button conditionally
  const commentRef = useRef(null); // To get reference to the comment text div
  // Function to dynamically generate star ratings
  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const emptyStars = 5 - filledStars;
    return (
      <>
        {Array(filledStars).fill().map((_, index) => (
          <img key={index} src={rankingpic} alt="Star" className="course-testimonial-star-img" />
        ))}
        {Array(emptyStars).fill().map((_, index) => (
          <img key={index} src={rankingstarpic} alt="Star" className="course-testimonial-star-img" />
        ))}
      </>
    );
  };

    // useEffect to check if the comment exceeds two lines
    useEffect(() => {
      const commentElement = commentRef.current;
      if (commentElement) {
        // Assuming 'line-height' is 1.2em, and two lines = 2.4em
        const maxTwoLineHeight = parseFloat(getComputedStyle(commentElement).lineHeight)*2;
         // Only show "See More" if the content overflows, meaning not fully visible within 2 lines
    if (commentElement.scrollHeight > commentElement.clientHeight && commentElement.clientHeight >= maxTwoLineHeight) {
      setShowSeeMoreButton(true); // Show See More button if content exceeds two fully visible lines
        }
      }
    }, []);

  return (
    <div className="course-testimonials-section">
      <div className="course-testimonial-card">
        <div className="course-testimonial-header">
          <img src={profileAvatar} alt="Profile" className="course-testimonial-profile-img" />
          <div className="course-testimonial-header-info">
            <div className="course-testimonial-name">{posted_by || "Anonymous"}</div>
            <div className="course-testimonial-designation">{user_role || "User"}</div>
          </div>
          <div className='course-testimonial-lines'>
            <img src={lines} alt="Icon" />
          </div>
        </div>
        <div
          className={`course-testimonial-review-text ${showFullText ? 'expanded' : 'collapsed'}`}
          ref={commentRef}
        >
          {comment}
        </div>
        <div className="course-testimonial-footer">
          <div className="course-testimonial-stars">
            {renderStars(rating)}
          </div>
          <div className="course-testimonial-rating">{rating.toFixed(1)}</div>
          <div className="course-testimonial-review-date">
            Reviewed on {new Date(date).toLocaleDateString()}
          </div>
        </div>
        {showSeeMoreButton && (
        <button className="course-testimonial-see-more-button" onClick={() => setShowFullText(!showFullText)}>
          {showFullText ? 'Read more' : 'Read less'}
        </button>
          )}
      </div>
    </div>
  );
};

const ReviewCardRow = () => {
  const [showMore, setShowMore] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id: courseId } = useParams();

  // Fetch the reviews from the API
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await adminaxios.get('/course_details_page/66eee8dec2bde2e5874728e7/');
        console.log('API Response:', response.data); // Log the API response to see its structure

        // Extracting testimonials from the API response
        const reviewsData = Array.isArray(response.data.testimonials) ? response.data.testimonials : [];
        setReviews(reviewsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [courseId]);

  return (
    <div>
      <h4 className="testimonials-heading">What Our Students Say</h4>

      <div className="course-testimonial-row" style={{ display: 'flex', gap: '50px', flexWrap: 'nowrap' }}>
        {/* Show skeleton while reviews are loading */}
        {loading
          ? // Display 3 skeletons when loading
            Array(3)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="testimonial-card" style={{ flexWrap:'1', minWidth: '100%' }}>
                  <TestimonialSkeleton />
                </div>
              ))
          : // Display actual reviews when loaded
            reviews.slice(0, 3).map((review, index) => (
              <div key={index} className="testimonial-card" style={{ flexWrap:'1', minWidth: '300px' }}>
                <ReviewCard review={review} />
              </div>
            ))}
      </div>

      {showMore && (
        <div className="course-testimonial-row" style={{ display: 'flex', gap: '50px', flexWrap: 'wrap' }}>
          {reviews.slice(3).map((review, index) => (
            <div key={index + 3} className="testimonial-card" style={{ flexWrap:'1', minWidth: '300px' }}>
              <ReviewCard review={review} />
            </div>
          ))}
        </div>
      )}

      {/* See More/Less Button */}
      {reviews.length > 3 && (
        <div className="course-details-see-more-container">
          <Button onClick={() => setShowMore(!showMore)} className="course-details-see-more-button">
            {showMore ? 'Less' : 'More'}
            <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReviewCardRow;
