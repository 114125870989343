

// import React, { useState, useEffect } from 'react';
// import { adminaxios } from '../../axios';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Button from '@mui/material/Button';
// import folder from '../../assets/Images/folder-2.png';
// import videoplay from '../../assets/Images/videoplay.png';
// import unlock from '../../assets/Images/unlock.png';
// import '../../assets/Css/RolebasedDetails.css';
// import { useParams } from 'react-router-dom';

// const Modules = ({ onModulesLoad }) => {
//     const [modules, setModules] = useState([]);
//     const [expandedPanels, setExpandedPanels] = useState([]);
//     const [showMore, setShowMore] = useState(false);
//       const { id: courseId } = useParams();


//     useEffect(() => {
//         const fetchModules = async () => {
//             try {
//                 const response = await adminaxios.get('/course_details_page/66eee8dec2bde2e5874728e7/');
//                 const data = response.data; // Axios stores the response data in `response.data`

//                 if (data && data.curriculum) {
//                     const transformedModules = data.curriculum.map((module, index) => ({
//                         id: index + 1,
//                         title: `Course ${index + 1} - ${module.course_title}`,
//                         // content: { text: module.course_title, time: '04:30' },
//                         // details: module.topics.map(topic => ({
//                         //     text: topic.title,
//                         //     time: '04:30',
//                         content: { text: module.course_title, time: '04:30' }, // Placeholder for time
//                         details: module.module_details.map((detail) => ({
//                             text: detail.title,

//                             time: '04:30' // Placeholder for time
//                         })),
//                     }));

//                     setModules(transformedModules);
//                     if (onModulesLoad) {
//                         onModulesLoad(transformedModules);
//                     }
//                 } else {
//                     console.error('API response does not contain expected curriculum data.');
//                 }
//             } catch (error) {
//                 console.error('Error fetching modules:', error);
//             }
//         };

//         fetchModules();
//     }, [courseId, onModulesLoad]);

//     const handleAccordionChange = (id) => (event, isExpanded) => {
//         setExpandedPanels((prevExpanded) =>
//             isExpanded ? [...prevExpanded, id] : prevExpanded.filter((panelId) => panelId !== id)
//         );
//     };

//     return (
//         <div className="course-details-second">

//                 <div className="course-single-moduletimed-quiz">
//                     <h4>Courses To Be Covered</h4>
//                     {/* {modules.slice(0, showMore ? modules.length : 4).map((module) => (
//             <Accordion
//               key={module.id}
//               expanded={expandedPanels.includes(module.id)}
//               onChange={handleAccordionChange(module.id)}
//               TransitionProps={{ unmountOnExit: true }} // Ensure smooth mounting/unmounting transitions
//               className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''}
//             >
//               <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls={`panel${module.id}-content`}
//                 id={`panel${module.id}-header`}
//               >
//                 <div className="accordion-summary-content">
//                   <span className="accordion-summary-title">{module.title}</span>
//                 </div>
//               </AccordionSummary>
//               <AccordionDetails className="accordion-details">
//                 <p className="course-module-divider"></p>
//                 <div className="course-module-container">
//                   <div className="course-module-content">
//                     <img src={folder} alt="Document Icon" className="course-content-icon" />
//                     <span className="course-content-text">{module.content.text}</span>
//                     <div className="course-detail-time">
//                       <img src={videoplay} alt="Video Icon" className="course-video-icon" />
//                       <span>{module.content.time}</span>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="course-module-subcontent">
//                   <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
//                   <span>Get the materials</span>
//                 </div>
//                 {module.details.map((detail, index) => (
//                   <div key={index} className="course-module-detail">
//                     <div className="course-detail-text">
//                       <span>{detail.text}</span>
//                     </div>
//                     <div className="course-detail-time">
//                       <img src={videoplay} alt="Video Icon" className="course-video-icon" />
//                       <span>{detail.time}</span>
//                     </div>
//                   </div>
//                 ))}
//               </AccordionDetails>
//             </Accordion>
//           ))} */}
//                     <div className="course-modules-container">
//                         {modules.slice(0, showMore ? modules.length : 4).map((module, index) => (
//                             <div className="module-card-wrappertimed-quiz" key={module.id}>
//                                 <Accordion
//                                     expanded={expandedPanels.includes(module.id)}
//                                     onChange={handleAccordionChange(module.id)}
//                                     TransitionProps={{ unmountOnExit: true }} // Ensure smooth mounting/unmounting transitions
//                                     className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''}
//                                 >
//                                     <AccordionSummary
//                                         expandIcon={<ExpandMoreIcon />}
//                                         aria-controls={`panel${module.id}-content`}
//                                         id={`panel${module.id}-header`}
//                                     >
//                                         <div className="accordion-summary-content">
//                                             <span className="accordion-summary-title">{module.title}</span>
//                                         </div>
//                                     </AccordionSummary>
//                                     <AccordionDetails className="accordion-details">
//                                         <p className="course-module-divider"></p>
//                                         <div className="course-module-container">
//                                             <div className="course-module-content">
//                                                 <img src={folder} alt="Document Icon" className="course-content-icon" />
//                                                 <span className="course-content-text">{module.content.text}</span>
//                                                 <div className="course-detail-time">
//                                                     <img src={videoplay} alt="Video Icon" className="course-video-icon" />
//                                                     <span>{module.content.time}</span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="course-module-subcontent">
//                                             <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
//                                             <span>Get the materials</span>
//                                         </div>
//                                         {module.details.map((detail, detailIndex) => (
//                                             <div key={detailIndex} className="course-module-detail">
//                                                 <div className="course-detail-text">
//                                                     <span>{detail.text}</span>
//                                                 </div>
//                                                 <div className="course-detail-time">
//                                                     <img src={videoplay} alt="Video Icon" className="course-video-icon" />
//                                                     <span>{detail.time}</span>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </AccordionDetails>
//                                 </Accordion>
//                             </div>
//                         ))}
//                     </div>

//                     <Button onClick={() => setShowMore(!showMore)} className="course-see-more-button">
//                         {showMore ? 'Show less' : 'See more'}
//                         <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
//                     </Button>
//                 </div>
//             </div>

//     );
// };

// export default Modules;





import React, { useState, useEffect } from 'react';
import { adminaxios } from '../../axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import folder from '../../assets/Images/folder-2.png';
import videoplay from '../../assets/Images/videoplay.png';
import resumeicon from '../../assets/Images/pause-circle@2x.png';
import { Button } from '@mui/material';
import unlock from '../../assets/Images/unlock.png';
import Tooltip from '@mui/material/Tooltip';
import '../../assets/Css/RolebasedDetails.css';
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate
import { useDispatch, useSelector } from 'react-redux';
import { setCourseId } from '../../actions/apisIdsActions';
import Animations from './RolebasedModuleSkeleton';

const Modules = ({ onModulesLoad }) => {
    const [modules, setModules] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedPanels, setExpandedPanels] = useState([])
    const [showMore, setShowMore] = useState(false);
    const { id: courseId } = useParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const dispatch = useDispatch();
    const dynamicCourseId = useSelector((state) => state.dynamicApiId.courseId);

    useEffect(() => {
        const fetchModules = async () => {
            setLoading(true);
            try {
                const response = await adminaxios.get(`/course_details_page/${dynamicCourseId}`);
                const data = response.data;
                setLoading(false); // Placeholder for loading state, replace with actual loading state from API response
                setCourses(data.curriculum); // Placeholder for course data, replace with actual course data from API response

                if (data && data.curriculum) {
                    // Map the module data using module_ids
                    const transformedModules = data.curriculum.map((module, index) => ({
                        id: module._id,  // Use the provided module_id here
                        title: `Course ${index + 1} - ${module.course_title}`,
                        content: { text: module.course_title, time: '04:30' }, // Placeholder for time
                        details: module.module_details.map((detail) => ({
                            text: detail.title,
                            time: '04:30' // Placeholder for time
                        })),
                    }));

                    setModules(transformedModules);
                    if (onModulesLoad) {
                        onModulesLoad(transformedModules);
                    }
                } else {
                    console.error('API response does not contain expected curriculum data.');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching modules:', error);
                setLoading(false);
            }
        };

        fetchModules();
    }, [courseId, onModulesLoad,dynamicCourseId]);

    const handleAccordionChange = (id) => (event, isExpanded) => {
        setExpandedPanels((prevExpanded) =>
            isExpanded ? [...prevExpanded, id] : prevExpanded.filter((panelId) => panelId !== id)
        );
    };


    // Function to handle "Resume" button click
    const handleResumeClick = (courseId) => {
        dispatch(setCourseId(courseId));
        localStorage.setItem('role_based_courseId', courseId);
        navigate(`/combine/${courseId}`); // Example route: /course/:courseId/module/:moduleId

    };


    return (
        <div className="course-details-second">
            <div className="course-single-moduletimed-quiz">
                <h4>Courses To Be Covered</h4>
                {/* Add Animation Component here to show loading below the heading */}
                {loading && <Animations />}

                {/* Render the modules only after loading is complete */}
                {!loading && (
                    <div className="course-modules-container">
                        {modules.slice(0, showMore ? modules.length : 4).map((module, index) => (
                            <div className="module-card-wrappertimed-quiz" key={module.id}>
                                <Accordion
                                    expanded={expandedPanels.includes(module.id)}
                                    onChange={handleAccordionChange(module.id)}
                                    TransitionProps={{ unmountOnExit: true }} // Ensure smooth mounting/unmounting transitions
                                    className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${module.id}-content`}
                                        id={`panel${module.id}-header`}
                                    >
                                        <div className="accordion-summary-content">
                                            <span className="accordion-summary-title">{module.title}</span>
                                            <div className="accordion-summary-row">
                                                <Tooltip title="Resume" placement="top" arrow>
                                                    <img
                                                        src={resumeicon}
                                                        onClick={() => handleResumeClick(module.id)} // Pass module_id
                                                        className="rolebased-resume-button"
                                                    />
                                                </Tooltip>
                                                <div className="rolebased-progress-container">
                                                    <div className="rolebased-progress-bar">
                                                        <div
                                                            className="rolebased-resume-progress"
                                                            style={{ width: '50%' }} // Adjust the progress percentage here
                                                        ></div>
                                                    </div>
                                                    <span className="progress-percentage">50%</span> {/* Display progress percentage */}
                                                </div>
                                            </div>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-details">
                                        <p className="course-module-divider"></p>
                                        <div className="course-module-container">
                                            <div className="course-module-content">
                                                <img src={folder} alt="Document Icon" className="course-content-icon" />
                                                <span className="course-content-text">{module.content.text}</span>
                                                <div className="course-detail-time">
                                                    <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                                                    <span>{module.content.time}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="course-module-subcontent">
                                            <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
                                            <span>Get the materials</span>
                                        </div>
                                        {module.details.map((detail, detailIndex) => (
                                            <div key={detailIndex} className="course-module-detail">
                                                <div className="course-detail-text">
                                                    <span>{detail.text}</span>
                                                </div>
                                                <div className="course-detail-time">
                                                    <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                                                    <span>{detail.time}</span>
                                                </div>
                                            </div>
                                        ))}


                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                )}

                {/* See More/Less Button - only visible if modules.length > 4 and after loading */}
                {!loading && modules.length > 4 && (
                    <Button onClick={() => setShowMore(!showMore)} className="course-see-more-button">
                        {showMore ? 'Show less' : 'See more'}
                        <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Modules;
