import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('tokens'); // Get the token from localStorage

    useEffect(() => {
      // If no valid token, navigate to the login page
      if (!isAuthenticated || isAuthenticated === 'null' || isAuthenticated === 'undefined') {
        navigate('/'); // Redirect to login if not authenticated
      }
    }, [isAuthenticated, navigate]);

    // If token is invalid, return a redirect message
    if (!isAuthenticated || isAuthenticated === 'null' || isAuthenticated === 'undefined') {
      return <div>Redirecting to login...</div>;
    }

    // If authenticated, return the wrapped component
    return isAuthenticated && <WrappedComponent {...props} /> ;
  };
};

export default withAuth;
