import React, { useState, useEffect, useRef, us } from 'react';
import MonacoEditor from '@monaco-editor/react';
import '../assets/Css/CodeEditor.css';
import { useNavigate } from 'react-router-dom';
import instruction_icon2 from '../assets/Images/Instruction2.png';
import Hint2_icon from '../assets/Images/Hint2.png';
import output_code_icon from '../assets/Images/output_code.png';
import Theme_icon from '../assets/Images/sun.png';
import Solution_icon2 from '../assets/Images/solution3.png';
import Play_icon from '../assets/Images/play.png';
import Backward_icon from '../assets/Images/Backward_icon_3.png';
import Fordward_icon from '../assets/Images/Fordward_icon_3.png';
import output_maximize_icon from '../assets/Images/Output_maximize_icon_2.png';
import { adminaxios } from '../axios/index';
import { useParams } from 'react-router-dom';
import Header1 from '../Header/Header1';
import light_theme_instuction_icon from '../assets/Images/Light_Theme_Instuction_icon.png';
import light_theme_hint_icon from "../assets/Images/Light_Theme_Hint_Icon.png";
import Closed_block_icon from "../assets/Images/Closed_left_block_icon.png";
import openLeftWindow_icon from "../assets/Images/OpenLeftWindow_icon.png";
import Closed_block_icon_light_theme from '../assets/Images/closed_block_lcon_light_theme_3.png';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { setParticularLabId } from '../actions/apisIdsActions';
import download_icon from '../assets/Images/download_icon1.png';
import  reset_icon from "../assets/Images/Loding_image.png";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import light_theme_reset_icon from '../assets/Images/Light_Theme_reset_icon.png';
import light_theme_play_icon from '../assets/Images/Light_Theme_Play_icon.png';
import output_maximize_light_icon from '../assets/Images/Output_Maximize_Light_Icon.png';
import Close_Outputsection_dark_theme_icon from '../assets/Images/close_outputsection_dark.png';
import Close_Outputsection_light_theme_icon from '../assets/Images/close_outputsection_light.png';
import Closed_LeftSide_icon_light_4 from "../assets/Images/Closed_LeftSide_icon_Light_4.png";
import submission_icon from "../assets/Images/Submissoin_icon_4.png";



const CodeEditor = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('challenge');
  const [content, setContent] = useState({ challenge: '', instructions: '', hints: '', solution: '' });
  const [leftWidth, setLeftWidth] = useState(40);
  const [editorWidth, setEditorWidth] = useState(60);
  const [outputWidth, setOutputWidth] = useState(0);
  const [showOutput, setShowOutput] = useState(false);
  const [outputExpanded, setOutputExpanded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isHovering_2, setIsHovering_2] = useState(false);
  const [isHovering_3, setIsHovering_3] = useState(false);
  const defaultContent = `print("Try Dataworks.pro")` + `\n`.repeat(22);
  const [showSolution, setShowSolution] = useState(false);
  const [outputs, setOutputs] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const challengeRef = useRef(null);
  const instructionsRef = useRef(null);
  const hintsRef = useRef(null);
  const solutionRef = useRef(null);
  const containerRef = useRef(null);
  const editorRef = useRef(null);
  const [isDraggingLeft, setIsDraggingLeft] = useState(false);
  const [isDraggingEditorOutput, setIsDraggingEditorOutput] = useState(false);
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const selectedLabId = localStorage.getItem('each_lab_id');
  const current_Instruction_icon = isDarkTheme ? instruction_icon2 : light_theme_instuction_icon;
  const current_Hint_icon = isDarkTheme ? Hint2_icon : light_theme_hint_icon;
  const [isActive, setIsActive] = useState(false);
  const [isLeftCollapsed, setIsLeftCollapsed] = useState(false);
  const [isOutputExpanded, setIsOutputExpanded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const webSocketRef = useRef(null);
  const [userInput, setUserInput] = useState('');
  const [waitingForInput, setWaitingForInput] = useState(false);
  const currentModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const currentLabId = useSelector((state) => state.dynamicApiId.labConfigId);
  const currentTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const [questionArray, setQuestionArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log(currentIndex, 'currentIndex')
  console.log(selectedLabId, 'each_lab_id')
  const [terminalContent, setTerminalContent] = useState('');
  const terminalRef = useRef(null);
  const [input_Submitting, setInput_Submitting] = useState(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [showEmptyCodePopup, setShowEmptyCodePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');


  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await adminaxios.get(
          `/modules/${currentModuleId}/topics/${currentTopicId}/question_bank_config/${currentLabId}/`
        );

        // Assuming the response contains the 'questions' array like in your provided data structure
        const questions = response.data.question_bank_configs?.questions || [];

        // Map through the questions and extract the `_id` into a new array
        const extractedTopicsArray = questions.map((question) => question._id);

        // Store the extracted question IDs in state
        setQuestionArray(extractedTopicsArray);
        const selectedLabId = localStorage.getItem('each_lab_id');

        // Find the index of the selectedLabId in the topicsArray
        const initialIndex = extractedTopicsArray.indexOf(selectedLabId);

        // If the ID is found, set the currentIndex to it, otherwise default to 0
        setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  const handleBackward = () => {
    if (currentIndex > 0) {
      setIsLoadingProgress(true);
      setTimeout(() => {
        setCurrentIndex(currentIndex - 1);
        dispatch(setParticularLabId(questionArray[currentIndex - 1]));
        setIsLoadingProgress(false); 
      }, 3000);
    }
  };
  
  const handleForward = () => {
    if (currentIndex < questionArray.length - 1) {
      setIsLoadingProgress(true);
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
        dispatch(setParticularLabId(questionArray[currentIndex + 1]));
        setIsLoadingProgress(false); 
      }, 3000);
    }
  };


  useEffect(() => {
    console.log(selectedLabId, "selectedLabId")
    // API call to fetch content
    adminaxios.get(`question_bank/${selectedLabId}/`)
      .then(res => {
        const projectLabContent = res.data.project_lab_content;
        setContent({
          title: projectLabContent.title || 'No title available.',
          challenge: projectLabContent.lab_question || 'No challenge available.',
          instructions: projectLabContent.lab_instructions || 'No instructions available.',
          hints: projectLabContent.lab_hints || 'No hints available.',
          solution: projectLabContent.lab_solution || 'No solution available.',
          testCases: projectLabContent.lab_test_cases || []
        });
      })
      .catch(error => console.error('Error fetching content:', error));
  }, [selectedLabId]);

  const handleExitLabClick = () => {
    navigate(`/lab-list/${courseId}`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case 'challenge':
        if (challengeRef.current) {
          challengeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'instructions':
        if (instructionsRef.current) {
          instructionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'hints':
        if (hintsRef.current) {
          hintsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        break;
    }
  };

  const handleSolutionClick = () => {
    setShowSolution(true);
  };

  const handleCloseSolution = () => {
    setShowSolution(false);
  };


  useEffect(() => {
    const ws = new WebSocket('wss://lmsbackend.nquantum.in/ws/execute/');
    webSocketRef.current = ws;

    // ws.onopen = () => {
    //   appendToTerminal('Connected to the server...\n');
    // };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.output) {
        appendToTerminal(response.output + '\n'); // Append output to terminal
      } else if (response.prompt) {
        appendToTerminal(response.prompt);  // Append prompt for user input
      } else if (response.error) {
        appendToTerminal('Error: ' + response.error + '\n');
      }
      setIsLoading(false);
    };

    // ws.onclose = () => {
    //   appendToTerminal('Connection closed...\n');
    // };

    // ws.onerror = (error) => {
    //   appendToTerminal('WebSocket error: ' + error.message + '\n');
    //   setIsLoading(false);
    // };

    return () => {
      ws.close();
    };
  }, []);


  const appendToTerminal = (data) => {
    setTerminalContent(prev => prev + data + '\n');
    setTimeout(() => {
      if (terminalRef.current) {
        terminalRef.current.scrollTop = terminalRef.current.scrollHeight; // Auto-scroll to the bottom
      }
    }, 100);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      const trimmedInput = userInput.trim(); // Capture the complete input

      if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
        // Send full user input to the WebSocket in the required format
        const payload = {
          action: 'input',  // Action to indicate input
          input: trimmedInput,  // The actual user input
        };
        webSocketRef.current.send(JSON.stringify(payload)); // Send input as JSON

        appendToTerminal(`"${trimmedInput}"\n`); // Append the input to terminal for display
        setUserInput(''); // Clear the input field after sending the input
        setWaitingForInput(false); // Stop waiting for input after sending
      } else {
        appendToTerminal('Error: WebSocket is not open\n'); // Handle WebSocket not open
      }
    } else {
      setUserInput(e.target.value);
    }
  };


  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [terminalContent]);



  const handleSubmitInput = () => {
    const trimmedInput = userInput.trim(); // Ensure input is clean
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      const payload = {
        action: 'input',
        input: trimmedInput,
      };

      console.log(payload, "payload");
      setInput_Submitting(true); // Set submitting to true to show loader
      webSocketRef.current.send(JSON.stringify(payload)); // Send input as JSON

      appendToTerminal(`"${trimmedInput}"\n`); // Append the input to terminal for display
      setUserInput(''); // Clear input field
      setWaitingForInput(false); // Stop waiting for input

      // Simulate loading complete after the input is processed (optional)
      setTimeout(() => {
        setInput_Submitting(false); // Hide loader after submission is processed
      }, 2000); // Adjust delay based on your actual processing time
    } else {
      appendToTerminal('Error: WebSocket is not open\n'); // Handle WebSocket not open
    }
  };


  const runCode = () => {
    const codeToExecute = editorRef.current.getValue(); // Capture the code from MonacoEditor
    
    // Check if the code is empty
    if (!codeToExecute || codeToExecute.trim() === '') {
      // Show the popup with the message
      setPopupMessage('Write the code to proceed.');
      setShowEmptyCodePopup(true);
  
      // Auto-close the popup after 3 seconds
      setTimeout(() => {
        setShowEmptyCodePopup(false);
      }, 3000);
  
      return; // Stop execution if code is empty
    }
  
    // If code is present, proceed with running the code
    setIsLoading(true);
    setOutputs([]); // Clear previous outputs
    setTerminalContent('');
  
    // Ensure output section opens when code runs
    if (!showOutput) {
      setShowOutput(true); // Open output section
      setLeftWidth(33.33);
      setEditorWidth(33.33);
      setOutputWidth(33.33);
    }
  
    // Send code via WebSocket
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      const payload = {
        action: 'execute',
        code: codeToExecute, // Send the code entered in MonacoEditor
        language: 'python', // Assuming Python is the language
      };
  
      // Send the code to the WebSocket server
      webSocketRef.current.send(JSON.stringify(payload));
  
      // Handle WebSocket message (output from the server)
      webSocketRef.current.onmessage = (event) => {
        try {
          // Parse the incoming data from the WebSocket
          const response = JSON.parse(event.data);
  
          if (response.output) {
            // If 'output' is present, append the output to the terminalContent
            const cleanedOutput = response.output.replace(/[\[\]"]/g, '');
            appendToTerminal(cleanedOutput);
            setIsError(false);
          } else if (response.prompt) {
            // If 'prompt' is present (e.g., asking for input), append the prompt to the terminalContent
            appendToTerminal(response.prompt);
            setWaitingForInput(true); // Flag to indicate waiting for user input
            setIsError(false);
          } else if (response.error) {
            // If 'error' is present, append the error to the terminalContent
            appendToTerminal(`Error: ${response.error}`);
            setIsError(true);
          } else {
            // If no known fields (output/prompt/error) are present, append a generic message
            appendToTerminal('Unknown response received');
            setIsError(true);
          }
  
          setIsLoading(false); // Stop loading
        } catch (error) {
          // Handle any errors parsing the WebSocket response
          console.error("Error parsing WebSocket response:", error);
          appendToTerminal('An error occurred while processing the response');
          setIsError(true);
          setIsLoading(false);
        }
      };
  
    } else {
      console.error('WebSocket is not open');
      appendToTerminal('Error: WebSocket is not open');
      setIsLoading(false);
    }
  };




  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    setIsActive(!isActive);

  };

  const toggleOutput = () => {
    if (!showOutput) {
      setShowOutput(true);
      setLeftWidth(33.33);
      setEditorWidth(33.33);
      setOutputWidth(33.33);
    }
  };



  const expandOutput = () => {
    // Always expand the output section when clicked
    setOutputExpanded(true);
    setIsOutputExpanded(true);

    setLeftWidth(0);
    setEditorWidth(50);
    setOutputWidth(50);
  };


  /* reset daat  */
  const resetEditor = () => {
    if (editorRef.current) {
      const emptyContent = `\n`.repeat(22);
      editorRef.current.setValue(emptyContent);
      editorRef.current.setPosition({ lineNumber: 1, column: 1 });
      editorRef.current.focus();
    }
  };


  // Draggable divider event handlers for left-right division
  const startDraggingLeft = (e) => {
    setIsDraggingLeft(true);
    e.preventDefault();
  };

  const stopDraggingLeft = () => {
    if (isDraggingLeft) {
      setIsDraggingLeft(false);
    }
  };


  const onDragLeft = (e) => {
    if (isDraggingLeft && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const newLeftWidth = (e.clientX / containerWidth) * 100;

      if (newLeftWidth > 24 && newLeftWidth < 90) {
        setLeftWidth(newLeftWidth);

        // Adjust editor width based on whether the output section is visible
        if (showOutput) {
          setEditorWidth(100 - newLeftWidth - outputWidth);
        } else {
          setEditorWidth(100 - newLeftWidth);
        }
      }
    }
  };


  // Draggable divider event handlers for editor-output division

  const startDraggingEditorOutput = (e) => {
    setIsDraggingEditorOutput(true);
    e.preventDefault();
  };

  const stopDraggingEditorOutput = () => {
    if (isDraggingEditorOutput) {
      setIsDraggingEditorOutput(false);
    }
  };

  const onDragEditorOutput = (e) => {
    if (isDraggingEditorOutput && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const newEditorWidth = (e.clientX / containerWidth) * 100 - leftWidth;
      if (newEditorWidth > 10 && newEditorWidth < 90) {
        setEditorWidth(newEditorWidth);
        setOutputWidth(100 - leftWidth - newEditorWidth);
      }
    }
  };


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const closeOutput = () => {
    setShowOutput(false);
    setIsOutputExpanded(false);
    setLeftWidth(40);
    setEditorWidth(60);
  };


  const toggleLeftSide = () => {
    setIsLeftCollapsed(!isLeftCollapsed);
    if (!isLeftCollapsed) {
      setLeftWidth(0);
      setEditorWidth(100);
    } else {
      setLeftWidth(40);
      setEditorWidth(60);
    }
  };

  const handleSubmitClick = () => {
    setIsPopupOpen(true);
  };

  // Handle confirmation inside the popup
  const handleConfirmSubmit = () => {
    console.log('Code submitted!');
    // Close the popup and add your actual submit logic here
    setIsPopupOpen(false);
  };

  // Close the popup if the user clicks "Cancel"
  const handleCancelSubmit = () => {
    setIsPopupOpen(false);
  };
  
  // Function to handle sending user input via WebSocket
  const handleWebSocketInput = (input) => {
    // Ensure input is not empty
    if (input.length === 0) return;

    // Ensure WebSocket is open and ready to send data
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      // Send input as a JSON object
      const payload = {
        action: 'input', // Action type
        input, // User input
      };

      webSocketRef.current.send(JSON.stringify(payload)); // Send to WebSocket

      appendToTerminal(`You entered: ${input}\n`); // Show the input in terminal
      setUserInput(''); // Clear the input field
      setWaitingForInput(false); // Stop waiting for input
    } else {
      appendToTerminal('Error: WebSocket is not open\n'); // Handle WebSocket closed case
    }
  };
  console.log(leftWidth);

/* Download */

  const downloadOutput = () => {
    const outputData = terminalContent; 
    const blob = new Blob([outputData], { type: 'text/plain' }); 
    const link = document.createElement('a'); 
    link.href = URL.createObjectURL(blob);  
    link.download = 'output.txt';  
    link.click();
    URL.revokeObjectURL(link.href);
  };




  return (

    <div
      className={`new_labpage-app-container ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}
      ref={containerRef}
      onMouseMove={(e) => {
        if (isDraggingLeft) onDragLeft(e);
        if (isDraggingEditorOutput) onDragEditorOutput(e);
      }}
      onMouseLeave={() => { stopDraggingLeft(); stopDraggingEditorOutput(); }}
      onMouseUp={() => { stopDraggingLeft(); stopDraggingEditorOutput(); }}
    >
      {/* <Header1 /> */}
      {showEmptyCodePopup && (
        <div className="new_lab_page_popup-overlay">
          <div className="new_lab_page_popup">
            <p>{popupMessage}</p>
          </div>
        </div>
      )}
      <div className="new_labpage-header-container">
        <div className="new_labpage-left-side-header">
          <button className="new_labpage-exit-button" onClick={handleExitLabClick}>Exit</button>
          <h3 className="new_labpage_Technology_name">Python</h3>
        </div>
        <div className="new_labpage-right-side-header">
          <div className="new_labpage-right-header-buttons"></div>
          <div className="new_labpage-right-header-actions">
            <Tooltip title="Output" arrow>
              <img
                src={output_code_icon}
                alt="OutPut-Code-Icon"
                className={`new_labpage-output-icon ${showOutput ? 'active' : ''}`}
                onClick={toggleOutput}
              />
            </Tooltip>
            <Tooltip title="Theme" arrow>
              <img src={Theme_icon} alt="OutPut-Code-Icon" className={`new_labpage-theme-icon ${isActive ? 'active' : ''}`} onClick={toggleTheme} />
            </Tooltip>
            <Tooltip title="Download" arrow>
              <img src={download_icon} alt="dowmload_icon" className={`new_labpage-download-icon ${isActive ? 'active' : ''}`} onClick={downloadOutput}/>
            </Tooltip>
            <button className="new_labpage-submit-button" onClick={handleSubmitClick} >Submit</button>
          </div>
          {isPopupOpen && (
            <div className="new_lab_page_popup-overlay">
              <div className="new_lab_page_popup">
                <p>Would you like to submit the code?</p>
                <div className="new_lab_page_popup-buttons">
                  <button className="new_lab_page_cancel-button" onClick={handleCancelSubmit}>Cancel</button>
                  <button className="new_lab_page_confirm-button" onClick={handleConfirmSubmit}>OK</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    {isLoadingProgress && (
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          sx={{
            height: 2,
            backgroundColor: '#f0f0f0', // Custom background color
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#56965c', // Bar color
            },
          }}
        />
      </Box>
    )}   

      <div className="new_labpage-app-content">
        {!showSolution ? (
          <div className=" new_labpage-left-side " style={{ width: `${leftWidth}%` }}>
            <div className="new_labpage-header" style={{ flexDirection: leftWidth >= 33.33 ? 'row' : 'column' }}>
              <div className="new_labpage-tab-buttons" style={{ alignSelf: leftWidth >= 33.33 ? '' : 'flex-start' ,marginLeft: leftWidth >= 33.33 ? '' : '32px' }}>
                <button
                  onClick={() => handleTabClick('challenge')}
                  className={activeTab === 'challenge' ? 'active' : ''}
                >
                  Challenge
                </button>
                <button
                  onClick={() => handleTabClick('instructions')}
                  className={activeTab === 'instructions' ? 'active' : ''}
                >
                  Instructions
                </button>
                <button
                  onClick={() => handleTabClick('hints')}
                  className={activeTab === 'hints' ? 'active' : ''}
                >
                  Hints
                </button>
              </div>
              <div className="new_labpage-solution-pagination" style={{ alignSelf: leftWidth >= 33.33 ? '' : 'flex-start', marginLeft: leftWidth >= 33.33 ? '' : '32px', marginTop: leftWidth >= 33.33 ? '' : '10px' }}>
                <div className="new_labpage-icon-solution-container" >
                  <img src={Solution_icon2} alt="Solution Icon" className="new_labpage-solution-icon" />
                  <p className="new_labpage-icon-solution" onClick={handleSolutionClick}>Solution</p>
                  {/* <button className='new_lab_submit_button_3'>submissions</button> */}
                  <div className="new_labpage_submissionIon-and-button">
                    <img src={submission_icon} alt="submission_Icon" className="new_labpage-submission_icon" />
                    <p className="new_lab_submit_button_3">submissions</p>
                    
                  </div>
                </div>
              </div>
              <div className="new_labpage-pagination" style={{ order: leftWidth >= 33.33 ? '0' : '-1', marginLeft: leftWidth >= 33.33 ? '' : '32px', alignSelf: leftWidth >= 33.33 ? '' : 'flex-start', marginBottom: leftWidth >= 33.33 ? '' : '20px' }}>
              <Tooltip title="Previous Question" arrow>
                {currentIndex > 0 && (
                  <img
                    src={Backward_icon}
                    alt="Previous"
                    className="new_labpage-Backward-icon"
                    onClick={handleBackward}
                  />
                )}
                </Tooltip>
                
                <span>{currentIndex + 1}/{questionArray.length}</span>
                {currentIndex < questionArray.length - 1 && (
                  <Tooltip title="Next Question" arrow>
                  <img
                    src={Fordward_icon}
                    alt="Next"
                    className="new_labpage-forward-icon"
                    onClick={handleForward}
                  />
                  </Tooltip>
                )}                
              </div>
            </div>
            <div className="new_labpage-content-container">
              <div ref={challengeRef} className="new_labpage-content-section">
                <h2 className="new_labpage_challenge_title">Challenge</h2>
                <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.challenge }} />
                <div className="new_labpage_challenge_pera">
                  {content.testCases && content.testCases.length > 0 && (
                    <div >
                      <h4 className="new_labpage_test_cases_title">Test Cases:</h4>
                      <ul>
                        {content.testCases.map((testCase, index) => (
                          <li key={index} >
                            <div dangerouslySetInnerHTML={{ __html: testCase.input }} />
                            <div dangerouslySetInnerHTML={{ __html: testCase.expected_result }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div ref={instructionsRef} className="new_labpage-content-section">
                <div className="new_labpage-instructions-header">
                  <img src={current_Instruction_icon} alt="Instructions Icon" className="new_labpage-instruction-icon" />
                  <h2 className=" new_labpage_challenge_title">Instructions</h2>
                </div>
                <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.instructions }} />
              </div>
              <div ref={hintsRef} className="new_labpage-content-section">
                <div className="new_labpage-hint-header">
                  {/* <img src={Hint2_icon} alt="Hints Icon" className="new_labpage-hint-icon" /> */}
                  <img src={current_Hint_icon} alt="Hints Icon" className="new_labpage-hint-icon" />
                  <h2 className=" new_labpage_challenge_title" >Hints</h2>
                </div >
                <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.hints }} />
              </div>
            </div>
          </div>
        ) : (
          <div className="new_labpage-left-side" style={{ width: `${leftWidth}%` }}>
            <div className="new_labpage-header">
              <div className="new_labpage-tab-back_buttons">
                <button onClick={handleCloseSolution}>Back</button>
              </div>
            </div>
            <div ref={solutionRef} className="new_labpage-content-section_solution">
              <h2 className=" new_labpage_challenge_title" >Solution</h2>
              <div className="new_labpage_challenge_pera" dangerouslySetInnerHTML={{ __html: content.solution }} />
            </div>
          </div>
        )}
        <div
          className="new_labpage-divider"
          onMouseDown={startDraggingLeft}
        >
          <div
            className={`new_lab_OpenClose_icon ${isLeftCollapsed ? 'collapsed' : ''}`}
            onClick={toggleLeftSide}
            onMouseEnter={() => setIsHovering(true)}  
            onMouseLeave={() => setIsHovering(false)}
          >
            {!isOutputExpanded && (
              isLeftCollapsed ? (
                <img src={openLeftWindow_icon} alt="Expand" className="new_lab_openLeftWindow_icon" />
              ) : (
                <img src={isHovering ? isDarkTheme ? Closed_LeftSide_icon_light_4 : Closed_block_icon : 
                isDarkTheme ? Closed_block_icon : Closed_LeftSide_icon_light_4 }           
                alt="Collapse"
                className="new_lab_closed_block_icon"
                />
              )
            )}
          </div>
        </div>
        <div className="new_labpage-editor-section"
          style={{ width: `${editorWidth}%` }}
        >
          <MonacoEditor
            height="90.4%"
            defaultLanguage="python"
            defaultValue={defaultContent}
            options={{
              lineNumbers: 'on',
              wordWrap: 'off',
              scrollBeyondLastLine: false,
              selectOnLineNumbers: true,
              lineNumbersMinChars: 6,
              fontSize: 14,
              lineHeight: 1.8,
              minimap: {
                enabled: true,
                size: 'fit',
                scale: 1,
                side: 'right',
                showSlider: 'always',
                renderCharacters: true,
                maxColumn: 160
              }
            }}
            theme={isDarkTheme ? 'vs-dark' : 'light'}
            onMount={(editor) => {
              editorRef.current = editor;
              editorRef.current.setPosition({ lineNumber: 1, column: 1 });
              editorRef.current.focus();
            }}
          />
          <div className="new_labpage-editor-footer">
            <div className="new_labpage-reset-container" onClick={resetEditor}>

              <img 
              src={isDarkTheme ? reset_icon : light_theme_reset_icon} 
              alt="Play Icon" className="new_labpage-reset-button" />

              <p className='new_lab_pagereset_text'>Reset</p>
            </div>
            <div className="new_labpage-run-container">
              <button className="new_labpage-run-button" onClick={runCode} disabled={isLoading}>
                {isLoading ? (
                  <div className="new_labpage-loader"></div>
                ) : (
                  <>
                    <img src={isDarkTheme? Play_icon : light_theme_play_icon} 
                    alt="Play Icon" className="new_labpage_run-code-icon" />
                    Run code
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {showOutput && (
          <div className="new_labpage-editor-output-divider" onMouseDown={startDraggingEditorOutput}>
            <div className="new_labpage-editor-output-divider-icon">||</div>
          </div>
        )}
        {showOutput && (
          <div
            className="new_labpage-output-section"
            style={{ width: `${outputWidth}%` }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="new_labpage-output-header">
              <span className="new_labpage-output-title">Output</span>
              <Tooltip title="Maximize" arrow>
                <img
                  src={isHovering_2 ? isDarkTheme ? output_maximize_light_icon : output_maximize_icon :
                  isDarkTheme ? output_maximize_icon : output_maximize_light_icon}
                  alt="Expand Icon"
                  className="new_labpage-expand-icon"
                  onClick={expandOutput }
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setIsHovering_2(true)}  
                  onMouseLeave={() => setIsHovering_2(false)}
                />
              </Tooltip>
              <img
                  src={ 
                    isHovering_3 ? isDarkTheme ? Close_Outputsection_dark_theme_icon : Close_Outputsection_light_theme_icon :
                    isDarkTheme ? Close_Outputsection_light_theme_icon :  Close_Outputsection_dark_theme_icon }
                  alt="Close Icon"
                  className="new_labpage-close-icon"
                  onClick={closeOutput}
                  onMouseEnter={() => setIsHovering_3(true)}  
                  onMouseLeave={() => setIsHovering_3(false)}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
            </div>
            <div className="new_lab_page_textarea-container">
              {(isLoading || input_Submitting) && (
                <div className="new_lab_page_textarea-loader">
                  <div className="new_lab_page_loader-2"></div>
                </div>
              )}
              <textarea
                ref={terminalRef}
                value={terminalContent + (waitingForInput ? userInput : '')} // Show terminal output and user input
                onChange={(e) => setUserInput(e.target.value)} // Capture user input as it's typed
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleWebSocketInput(userInput.trim()); // Send trimmed input to WebSocket on "Enter"
                  }
                }}
                style={{
                  width: '100%',
                  height: '70vh',
                  // backgroundColor: '#2C2F35',
                  color: '#56965c',
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  padding: '10px',
                  border: 'none',
                  resize: 'none',
                  outline: 'none',
                }}
                className='new_labpage-terminal-textarea'
              />
            </div>
            <div className={`new_labpage-output-console ${isError ? 'error' : ''}`}>
              {outputs.map((output, index) => (
                <div key={index} style={{ whiteSpace: 'pre-wrap' }}>{output}</div>
              ))}
              {waitingForInput && (
                <div className="new_labpage-input-prompt">
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Enter your input here"
                    className="new_labpage_custom-input-field"
                  />
                  <button onClick={handleSubmitInput} className="new_labpage_custom-submit-button_2">
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CodeEditor;



