 
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../assets/Css/QuizPage.css';
import '../assets/Css/TimedQuizPage.css';
import infocircle from '../assets/Images/info-circle.png';
import leftarrow from '../assets/Images/arrow-left.png';
import rightarrow from '../assets/Images/arrow-right.png';
import OutlinedFlagSharpIcon from '@mui/icons-material/OutlinedFlagSharp';
import Tooltip from '@mui/material/Tooltip';
import Exiticon from '../assets/Images/logout.png';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { adminaxios } from '../axios/index';
 
const TimedQuizPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navContainerRef = useRef(null);
  const { id: courseId } = useParams();
 
  const quizDataList = location.state?.quizData?.question_bank_ids || [];
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizData, setQuizData] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(quizDataList.length).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [unattemptedAnswers, setUnattemptedAnswers] = useState(0);
  const [answerCorrectness, setAnswerCorrectness] = useState(new Array(quizDataList.length).fill(null));
  const [error, setError] = useState(null);
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [allQuestionOptions, setAllQuestionOptions] = useState({});
 
  const [reportDetails, setReportDetails] = useState({
    incorrectQuestion: false,
    unresponsiveAnswer: false,
    correctAnswerUnavailable: false,
    explanationNotVisible: false,
    correctOptionUnavailable: false,
    ctaNotWorking: false,
    others: '',
    files: []
  });
  const [showUnattemptedPopup, setShowUnattemptedPopup] = useState(false);
  const [unattemptedQuestionNumbers, setUnattemptedQuestionNumbers] = useState([]);
  const [isAttemptingUnattempted, setIsAttemptingUnattempted] = useState(false);
  const [currentUnattemptedIndex, setCurrentUnattemptedIndex] = useState(0);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState([0, 10]);
 
  const moduleId = "66ed3f2efa095c767878c349"; // Static module_id
  const topicId = "66ed3f2efa095c767878c347"; // Static topic_id
  const quizId = "66ed3f2efa095c767878c348"; // Static quiz_id (you can set it dynamically)
  console.log(quizId);  // Log the quiz ID to ensure it's correctly fetched
 
 
 
  const correctMessages = [
    "Fantastic job! You nailed it.",
    "Well done! That’s the right answer.",
    "Impressive! You’re really on top of this.",
    "Great work! Keep it up!",
    "Excellent! You got it right."
  ];
 
  const incorrectMessages = [
    "Oops, not quite. Give it another shot.",
    "Close, but not correct. Try again!",
    "Not this time, but don’t give up!",
    "Incorrect, but you’re learning. Keep going!",
    "Almost! Review and try once more."
  ];
 
  // const getToken = () => {
  //   return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI2NzY0NjY1LCJpYXQiOjE3MjY3MjE0NjUsImp0aSI6IjViYjg1ZGYxZTdmMzQwNjY4NzNiZGM4ZWZjM2IwNjMxIiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJsbXMxIiwicm9sZSI6IkFkbWluIn0.VDFy8OvEfFa1OPNNOgHqVqjvWtjHaOUZYLQz1jwp5gA';
  // };
 
  const fetchQuestion = useCallback(async (questionId) => {
    setLoading(true);
    try {
      const response = await adminaxios.get(`/question_bank/${questionId}/`, {
        // headers: {
        //   Authorization: `Bearer ${getToken()}`,
        // },
      });
      const questionData = response.data.quiz_content;
      setQuizData(questionData);
      // Store the options for this question in allQuestionOptions
      setAllQuestionOptions((prevOptions) => ({
        ...prevOptions,
        [questionId]: questionData.question_options, // Store options using the questionId as the key
 
      }));
 
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);
 
 
 
  useEffect(() => {
    if (location.state?.reattempt) {
      handleReattempt();
    } else if (quizDataList.length > 0) {
      fetchQuestion(quizDataList[currentQuestionIndex]);
    } else {
      setLoading(false);
      setError('No question IDs available');
    }
  }, [currentQuestionIndex, quizDataList, fetchQuestion, location.state]);
 
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 200);
    return () => clearInterval(interval);
  }, []);
 
  const updateVisibleQuestions = (index) => {
    let startIndex = index;
    let endIndex = startIndex + 10;
    if (endIndex > quizDataList.length) {
      endIndex = quizDataList.length;
      startIndex = Math.max(0, endIndex - 10);
    }
    setVisibleQuestions([startIndex, endIndex]);
  };
 
  const scrollToActiveQuestion = (index) => {
    const activeButton = document.getElementById(`question-${index}`);
    if (activeButton && navContainerRef.current) {
      const container = navContainerRef.current;
      const buttonOffsetLeft = activeButton.offsetLeft;
      container.scrollTo({
        left: buttonOffsetLeft - container.offsetLeft - 100,
        behavior: 'smooth',
      });
    }
  };
 
  const scrollLeft = () => {
    const [start] = visibleQuestions;
    if (start > 0) {
      const newStart = Math.max(0, start - 5);
      setVisibleQuestions([newStart, newStart + 10]);
    }
  };
 
  const scrollRight = () => {
    const [, end] = visibleQuestions;
    if (end < quizDataList.length) {
      const newStart = Math.min(quizDataList.length - 10, end - 5);
      setVisibleQuestions([newStart, newStart + 10]);
    }
  };
 
  useEffect(() => {
    updateVisibleQuestions(currentQuestionIndex);
    scrollToActiveQuestion(currentQuestionIndex);
  }, [currentQuestionIndex]);
 
  const handleNavigationClick = (index) => {
    scrollToActiveQuestion(index);
    updateVisibleQuestions(index);
    setCurrentQuestionIndex(index);
  };
 
 
  // const handleAnswerSelect = (optionText) => {
  //   if (quizData.question_type === 'multiple_options') {
  //     // Multiple options (checkbox logic)
  //     setSelectedAnswers(prev => {
  //       const updatedAnswers = [...prev];
  //       const currentSelected = updatedAnswers[currentQuestionIndex]?.selected_option || [];
 
  //       if (currentSelected.includes(optionText)) {
  //         updatedAnswers[currentQuestionIndex] = {
  //           question_id: quizDataList[currentQuestionIndex],
  //           selected_option: currentSelected.filter(opt => opt !== optionText),
  //         };
  //       } else {
  //         updatedAnswers[currentQuestionIndex] = {
  //           question_id: quizDataList[currentQuestionIndex],
  //           selected_option: [...currentSelected, optionText],
  //         };
  //       }
 
  //       return updatedAnswers;
  //     });
  //   } else {
  //     // Single option (radio button logic)
  //     setSelectedAnswers(prev => {
  //       const updatedAnswers = [...prev];
  //       updatedAnswers[currentQuestionIndex] = {
  //         question_id: quizDataList[currentQuestionIndex],
  //         selected_option: optionText, // Store the selected option
  //       };
  //       return updatedAnswers;
  //     });
  //     setSelectedAnswer(optionText);
  //   }
  // };
 
 
  const handleAnswerSelect = (optionText) => {
    let storedAnswers = JSON.parse(localStorage.getItem("selectedAnswers")) || [];
 
    if (quizData.question_type === 'multiple_options') {
      // Multiple options (checkbox logic)
      setSelectedAnswers((prev) => {
        const updatedAnswers = [...prev];
        const currentSelected = updatedAnswers[currentQuestionIndex]?.selected_option || [];
 
        if (currentSelected.includes(optionText)) {
          updatedAnswers[currentQuestionIndex] = {
            question_id: quizDataList[currentQuestionIndex],
            selected_option: currentSelected.filter((opt) => opt !== optionText),
          };
        } else {
          updatedAnswers[currentQuestionIndex] = {
            question_id: quizDataList[currentQuestionIndex],
            selected_option: [...currentSelected, optionText],
          };
        }
 
        // Update localStorage
        const existingAnswerIndex = storedAnswers.findIndex(
          (answer) => answer.question_id === quizDataList[currentQuestionIndex]
        );
        if (existingAnswerIndex >= 0) {
          storedAnswers[existingAnswerIndex] = updatedAnswers[currentQuestionIndex];
        } else {
          storedAnswers.push(updatedAnswers[currentQuestionIndex]);
        }
        localStorage.setItem("selectedAnswers", JSON.stringify(storedAnswers));
 
        return updatedAnswers;
      });
    } else {
      // Single option (radio button logic)
      setSelectedAnswers((prev) => {
        const updatedAnswers = [...prev];
        updatedAnswers[currentQuestionIndex] = {
          question_id: quizDataList[currentQuestionIndex],
          selected_option: optionText, // Store the selected option
        };
 
        // Update localStorage
        const existingAnswerIndex = storedAnswers.findIndex(
          (answer) => answer.question_id === quizDataList[currentQuestionIndex]
        );
        if (existingAnswerIndex >= 0) {
          storedAnswers[existingAnswerIndex] = updatedAnswers[currentQuestionIndex];
        } else {
          storedAnswers.push(updatedAnswers[currentQuestionIndex]);
        }
        localStorage.setItem("selectedAnswers", JSON.stringify(storedAnswers));
 
        return updatedAnswers;
      });
      setSelectedAnswer(optionText);
    }
  };
 
 
  const handleNext = () => {
    if (isAttemptingUnattempted) {
      const nextUnattemptedIndex = currentUnattemptedIndex + 1;
      if (nextUnattemptedIndex < unattemptedQuestionNumbers.length) {
        setCurrentUnattemptedIndex(nextUnattemptedIndex);
        const nextQuestionIndex = unattemptedQuestionNumbers[nextUnattemptedIndex] - 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedAnswer(selectedAnswers[nextQuestionIndex]);
        setLoading(true);
      } else {
        handleSubmit();
      }
    } else {
      if (selectedAnswer === null) {
        setUnattemptedAnswers(prev => prev + 1);
      }
      if (currentQuestionIndex < quizDataList.length - 1) {
        const nextQuestionIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedAnswer(selectedAnswers[nextQuestionIndex]);
        setLoading(true);
      } else {
        handleSubmit();
      }
    }
  };
 
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      const prevQuestionIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevQuestionIndex);
      setSelectedAnswer(selectedAnswers[prevQuestionIndex]);
    }
  };
 
 
  const handleContinueQuiz = () => {
    setShowExitPopup(false);
  };
 
  // Function to handle quiz submission and navigate to the results page
  const handleQuizSubmit = async () => {
    setLoading(true);
    const payload = {
      quiz_id: quizId,
      module_id: moduleId,
      topic_id: topicId,
      total_questions: quizDataList.length,
      submitted_answers: selectedAnswers.filter((answer) => answer !== null), // Remove any unanswered questions
    };
    console.log(payload, "payload");
    try {
      const response = await adminaxios.post('/submit_quiz/', payload, {
        // headers: {
        //   Authorization: `Bearer ${getToken()}`, // Replace getToken() with your token logic
        // },
      });
      console.log('Submission successful', response);
 
      // Store the response data in localStorage or pass it directly to the results page
      const quizResults = response.data; // Assuming API returns the quiz results in response.data
 
      // Navigate to the results page after submission
      navigate(`/timedquiz-results/${courseId}`, { state: { quizResults, quizDataList, allQuestionOptions } });
    } catch (error) {
      console.error("Error submitting quiz", error);
    }
  };
 
 
  const handleSubmit = () => {
    const unattemptedQuestions = selectedAnswers
      .map((answer, index) => (answer === null || answer === "") ? index + 1 : null)
      .filter(index => index !== null); // Get all unattempted question numbers
 
    setUnattemptedQuestionNumbers(unattemptedQuestions); // Store unattempted question numbers
 
    if (unattemptedQuestions.length > 0) {
      // If there are unattempted questions, show the unattempted questions popup
      setShowUnattemptedPopup(true);
    } else {
      // If all questions are attempted, proceed to submit the quiz
      handleQuizSubmit(); // Submit the quiz if no unattempted questions
    }
  };
 
  const handleContinueToResults = () => {
    setShowUnattemptedPopup(false); // Close the popup
    handleQuizSubmit(); // Submit the quiz without attempting unattempted questions
  };
 
  // const handleAttemptUnattempted = () => {
  //   setIsAttemptingUnattempted(true);
  //   setCurrentUnattemptedIndex(0);
  //   if (unattemptedQuestionNumbers.length > 0) {
  //     const firstUnattemptedIndex = unattemptedQuestionNumbers[0] - 1;
  //     setCurrentQuestionIndex(firstUnattemptedIndex);
  //     setSelectedAnswer(null);
  //     setShowUnattemptedPopup(false);
  //     setLoading(true);
  //   }
  // };

  const handleAttemptUnattempted = () => {
    setShowUnattemptedPopup(false); 
  }
 
 
 
  // Handle quiz submission when user clicks the Exit button
  const handleExitQuiz = () => {
    setShowExitPopup(true);
    // Reuse the same API call for submission when exiting
  };
  // const handleConfirmExit = async () => {
  //   setLoading(true);
  //   // Close the modal immediately
  //   setShowExitPopup(false);
 
  //   // Submit the quiz and navigate to the results page without delay
  //   await handleQuizSubmit(); // Ensure the quiz submission is completed before navigating
  //   navigate('/timedquiz-results', { state: { quizResults: response.data, quizDataList, allQuestionOptions } });
  // };
 
 
  const handleConfirmExit = async () => {
    setShowExitPopup(false); // Close the modal immediately
   
      // Save the latest selected answers to local storage before submission
  localStorage.setItem("selectedAnswers", JSON.stringify(selectedAnswers));
 
    try {
      // Submit the quiz and get the quiz results
      const quizResults = await handleQuizSubmit();
     
      // If quiz results are successfully fetched, navigate to the results page
      if (quizResults) {
        setLoading(true);
        navigate(`/timedquiz-results/${courseId}`, { state: { quizResults, quizDataList, allQuestionOptions } });
      } else {
        console.error("Failed to submit quiz");
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error("Error during quiz submission on exit:", error);
      // Handle the error, e.g., show a message to the user or retry
    }
  };
 
 
 
 
  const handleReattempt = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswer(null);
    setSelectedAnswers(new Array(quizDataList.length).fill(null));
    setAnswerCorrectness(new Array(quizDataList.length).fill(null));
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    setUnattemptedAnswers(0);
    setLoading(true);
    fetchQuestion(quizDataList[0]);
  };
 
  const getAppreciationMessage = () => {
    const isCorrect = selectedAnswer === quizData.question_options.find(option => option.is_correct)?.option_text;
    return isCorrect
      ? correctMessages[currentQuestionIndex % correctMessages.length]
      : incorrectMessages[currentQuestionIndex % incorrectMessages.length];
  };
 
  const handleReportChange = (event) => {
    const { name, checked, value } = event.target;
    setReportDetails(prevState => ({
      ...prevState,
      [name]: checked ? checked : value
    }));
  };
 
  const handleReportClick = () => {
    setShowReportPopup(true);
  };
 
  const handleReportSubmit = () => {
    setShowReportPopup(false);
    setReportDetails(prevState => ({
      ...prevState,
      files: []
    }));
  };
 
  const handleClosePopup = () => {
    setShowReportPopup(false);
    setReportDetails(prevState => ({
      ...prevState,
      files: []
    }));
  };
 
  const onDrop = useCallback((acceptedFiles) => {
    setReportDetails(prevState => ({
      ...prevState,
      files: acceptedFiles
    }));
  }, []);
 
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
 
 
 
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Oval height={50} width={50} color="#000080" ariaLabel="loading" />
      </div>
    );
  }
 
  if (error) {
    return <div>{error}</div>;
  }
 
  if (!quizData) {
    return <div>No question available</div>;
  }
 
  const isQuestionLong = quizData?.question_text?.length > 50;
  const codeSnippet = quizData?.code_snippet; // Assuming the backend sends code snippet in quizData
 
 
  return (
    <div className="quizRendering-container2">
      <div className={`${showReportPopup ? 'blur-background' : ''}`}>
        <div className="quizRendering-nav-report-button">
          <div className='quizRendering-nav-left-right-arrow'>
            {visibleQuestions[0] > 0 && (
              <img src={leftarrow} className="scroll-left-button" onClick={scrollLeft} alt="Left Arrow" />
            )}
 
            <div className="quizRendering-navigation-buttons2" ref={navContainerRef}>
              {quizDataList.slice(visibleQuestions[0], Math.min(visibleQuestions[0] + 10, quizDataList.length - 1)).map((_, index) => {
                const actualIndex = visibleQuestions[0] + index;
                return (
                  <button
                    key={actualIndex}
                    id={`question-${actualIndex}`}
                    className={`quizRendering-nav-button
            ${currentQuestionIndex === actualIndex ? 'active' : ''}
            ${selectedAnswers[actualIndex] !== null
                        ? 'attempted-blue' // Add this class for attempted questions
                        : 'unattempted-gray' // Add this class for unattempted questions
                      }`}
                    onClick={() => handleNavigationClick(actualIndex)}
                  >
                    {actualIndex + 1}
                  </button>
                );
              })}
 
              {quizDataList.length > 10 && visibleQuestions[0] + 10 < quizDataList.length - 1 && (
                <span className="quizRendering-dots">...</span>
              )}
 
              {quizDataList.length > 10 && (
                <button
                  key={quizDataList.length - 1}
                  id={`question-${quizDataList.length - 1}`}
                  className={`quizRendering-nav-button
          ${currentQuestionIndex === quizDataList.length - 1 ? 'active' : ''}
          ${selectedAnswers[quizDataList.length - 1] !== null
                      ? 'attempted-blue' // Apply blue for attempted questions
                      : 'unattempted-gray' // Apply gray for unattempted questions
                    }`}
                  onClick={() => handleNavigationClick(quizDataList.length - 1)}
                >
                  {quizDataList.length}
                </button>
              )}
            </div>
 
            {visibleQuestions[1] < quizDataList.length && (
              <img src={rightarrow} className="scroll-right-button" onClick={scrollRight} alt="Right Arrow" />
            )}
          </div>
 
 
          <div className="quizRendering-reporticon-Exit-button-submit">
            {currentQuestionIndex < quizDataList.length - 1 && (
              <Tooltip title="Exit Quiz" placement="top" arrow>
                <button className="quizRendering-exit-button2" onClick={handleExitQuiz}>
                  <img src={Exiticon} alt="Exit Icon" className="quizRendering-exitbutton-icon" />
                </button>
              </Tooltip>
            )}
 
            <Tooltip title="Report" placement="top" arrow>
              <OutlinedFlagSharpIcon
                sx={{
                  fontSize: 'small',
                  color: '#4A4A4A',
                  transform: 'scaleY(0.9)',
                }}
                className="quizRendering-reportbutton-icon"
                onClick={handleReportClick}
              />
            </Tooltip>
 
            <button className="quizRendering-submit-button2" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
 
      <div className={`quizRendering-content-wrapper-timed ${selectedAnswer !== null ? 'expanded' : ''}`}>
        <div className={`quizRendering-question-options-timed ${isQuestionLong ? 'limited' : ''}`}>
          <div className="quizRendering-question-container">
            <span className="quizRendering-question-numbertimed">
              {`${currentQuestionIndex + 1}.`}
            </span>
 
            {quizData?.question_text && (
              <div className="timedquizcode-snippet-container">
                {/* Render the instruction part of the question */}
                <p className="quizRendering-question-text">
                  {quizData.question_text
                    .split('<code>')[0]  // Instruction part before the <code> tag
                    .replace(/<br>/g, '\n')  // Replace <br> with new lines
                  }
                </p>
 
                {/* Check if there's a code snippet part */}
                {quizData.question_text.split('<code>')[1] && (
                  <pre className="timedquizcode-snippet" style={{ backgroundColor: 'black', color: 'white', padding: '10px' }}>
                    <code>
                      {quizData.question_text
                        .split('<code>')[1]  // Extract the part after <code>
                        .split('</code>')[0]  // Extract the part before </code>
                        .replace(/<br>/g, '\n')  // Replace <br> tags with new lines
                      }
                    </code>
                  </pre>
                )}
              </div>
            )}
 
          </div>
          {quizData?.question_options?.map((option, index) => (
            <div
              key={index}
              className={`quizRendering-option2
      ${quizData.question_type === 'multiple_options'
                  ? selectedAnswers[currentQuestionIndex]?.selected_option?.includes(option.option_text)
                    ? 'attempted-blue'
                    : 'unattempted-gray'
                  : selectedAnswers[currentQuestionIndex]?.selected_option === option.option_text
                    ? 'attempted-blue'
                    : 'unattempted-gray'
                }`}
              onClick={() => handleAnswerSelect(option.option_text)}
            >
              {quizData.question_type === 'multiple_options' ? (
                <input
                  type="checkbox"
                  name={`answer_${currentQuestionIndex}`}
                  id={`option${index}`}
                  checked={selectedAnswers[currentQuestionIndex]?.selected_option?.includes(option.option_text)}
                  onChange={() => handleAnswerSelect(option.option_text)}
                />
              ) : (
                <input
                  type="radio"
                  name={`answer_${currentQuestionIndex}`}
                  id={`option${index}`}
                  checked={selectedAnswers[currentQuestionIndex]?.selected_option === option.option_text}
                  onChange={() => handleAnswerSelect(option.option_text)}
                />
              )}
              <label
                htmlFor={`option${index}`}
                className={`${quizData.question_type === 'multiple_options'
                  ? selectedAnswers[currentQuestionIndex]?.selected_option?.includes(option.option_text)
                    ? 'attempted-text'
                    : 'unattempted-text'
                  : selectedAnswers[currentQuestionIndex]?.selected_option === option.option_text
                    ? 'attempted-text'
                    : 'unattempted-text'
                  }`}
              >
                {option.option_text}
              </label>
            </div>
          ))}
 
 
 
        </div>
      </div>
 
      <div className="quizRendering-button-group2">
        {currentQuestionIndex > 0 && (
          <button
            className={`quizRendering-previousbutton2 ${currentQuestionIndex === quizDataList.length - 1 ? 'last-question-previous-button' : ''}`}
            onClick={handlePrevious}
            style={currentQuestionIndex === quizDataList.length - 1 ? { marginLeft: '24.5%' } : {}}
          >
            Previous
          </button>
        )}
        {currentQuestionIndex === 0 && (
          <button className="quizRendering-previousbutton2 quizRendering-placeholder" disabled></button>
        )}
        {currentQuestionIndex < quizDataList.length - 1 ? (
          <button className="quizRendering-nextbutton2" onClick={handleNext}>
            Next
          </button>
        ) : ('')}
      </div>
 
 
      {showReportPopup && (
        <div className="quizRendering-popup-container">
          <div className="quizRendering-report-issue-popup">
            <div className="quizRendering-report-heading">
              <img src={infocircle} alt="Report Icon" className="quizRendering-report-icon" />
              <p>Report an issue</p>
            </div>
            <div className="quizRendering-report-checkboxes">
              <p className="quizRendering-report-paragraph">Which type of issues are you reporting?</p>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="incorrectQuestion" onChange={handleReportChange} />
                Question is incorrect
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="unresponsiveAnswer" onChange={handleReportChange} />
                Answer option is unresponsive
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="correctAnswerUnavailable" onChange={handleReportChange} />
                Correct answer option unavailable
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="explanationNotVisible" onChange={handleReportChange} />
                Explanation was not visible
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="correctOptionUnavailable" onChange={handleReportChange} />
                Correct answer option unavailable
              </label>
              <label className="quizRendering-checkbox-label">
                <input type="checkbox" name="ctaNotWorking" onChange={handleReportChange} />
                CTA are not working
              </label>
              <p className="quizRendering-checkbox-label">Others</p>
              <div className="quizRendering-others-container">
                <textarea name="others" onChange={handleReportChange} placeholder="Write your report here..."></textarea>
                <div {...getRootProps({ className: 'drop-box' })}>
                  <input {...getInputProps()} />
                  {reportDetails.files?.length === 0 ? (
                    <p className="quizRendering-Drag-Drop-Message">Drop your reports</p>
                  ) : (
                    <div className="quizRendering-report-file-names">
                      {reportDetails?.files?.map(file => (
                        <div key={file.path}>{file.path}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="quizRendering-report-buttons">
              <button className="quizRendering-reportcancel-button" onClick={handleClosePopup}>Cancel</button>
              <button className="quizRendering-reportsubmit-button" onClick={handleReportSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}
 
      {showUnattemptedPopup && (
        <div className="quizRendering-popup-container">
          <div className="quizRendering-unattempted-questions-popup">
            <p>You haven't answered the following questions: </p>
            <p style={{ color: "red" }}>{unattemptedQuestionNumbers.join(', ')}</p>
            <p>Would you like to attempt these questions now?</p>
            <div className="quizRendering-unattempted-buttons">
              <button onClick={handleContinueToResults}>Skip and Proceed</button>
              <button onClick={handleAttemptUnattempted}>Yes</button>
            </div>
          </div>
        </div>
      )}
 
      {showExitPopup && (
        <div className="quizRendering-exit-popup-container">
          <div className="quizRendering-exit-popup">
            <p>Are you sure you want to exit the quiz?</p>
            <div className="quizRendering-exit-buttons">
              <button className="quizRendering-exit-quiz-button" onClick={handleConfirmExit}>Exit Quiz</button>
              <button className="quizRendering-exit-continue-button" onClick={handleContinueQuiz}>Continue Quiz</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
 
export default TimedQuizPage;