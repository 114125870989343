// import React from 'react'
// import { useSelector } from "react-redux";
// import Sidebar from '../Sidebar/Sidebar';

// const Learning = () => {
//   const basicSidebarToggle = useSelector(
//     (state) => state.basicSidebar.basicSideToggle
//   );

//   return (
//     <div
//     style={{
//       display: "flex",
//       justifyContent: "flex-start",
//       height: "90vh",
//       width: basicSidebarToggle ? "100%" : "100%",
     
//     }}
//     >
//       <Sidebar />
//       <div style={{padding:'20px'}}>
//       <h4>Learning in Progress</h4>
//       </div>
      
//     </div>
//   )
  
// }

// export default Learning


import React from 'react'
import Footer from '../Footer/footer'

const Learning = () => {
  return (
    <div>
      <h4> My learning</h4>
      <div className="Course-list-footer">
            <Footer />
      </div>
    </div>
  )
}

export default Learning
