// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { adminaxios } from '../axios';
// import { useParams } from 'react-router-dom';
// import { setTopicId } from '../actions/apisIdsActions';
// import '../assets/Css/notes.css';
// import filledbubble from '../assets/Images/Ellipse 648.png';
// import unfilledbubble from '../assets/Images/Ellipse 651.png';
// import { Search } from 'react-feather';
// import Sidebar2 from '../Sidebar/Sidebar2'; 
 
// function Notes() {
//   const [activeTab, setActiveTab] = useState(null);
//   const [notesTitle, setNotesTitle] = useState('');
//   const [notes, setNotes] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [headings, setHeadings] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
 
//   const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
//   const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
//   const dispatch = useDispatch();
//   const { id: courseId } = useParams();
 
 
//   const handleScrollTo = (id) => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//       element.style.paddingTop = '20px';
//       element.style.transition = 'padding 0.3s ease-in-out';
//       setActiveTab(id);
//     } else {
//       console.error(`No element found with ID ${id}`);
//     }
//   };
 
 
//   const filteredHeadings = headings.filter(heading =>
//     heading.text.toLowerCase().includes(searchTerm.toLowerCase())
//   );
//   useEffect(() => {
//     if (notes) {
//       const preElements = document.querySelectorAll('.notes pre');
  
//       preElements.forEach((pre) => {
//         // Check if the copy button already exists to avoid duplicates
//         if (!pre.parentNode.classList.contains('pre-wrapper')) {
//           // Wrap the <pre> inside a div for easier styling
//           const wrapper = document.createElement('div');
//           wrapper.classList.add('pre-wrapper');
//           pre.parentNode.insertBefore(wrapper, pre);
//           wrapper.appendChild(pre);
  
//           // Create and insert the "Copy Code" button
//           const copyButton = document.createElement('button');
//           copyButton.classList.add('copy-code-btn');
//           copyButton.innerText = 'Copy Code';
  
//           copyButton.onclick = () => {
//             const code = pre.innerText;
//             navigator.clipboard.writeText(code)
//               .then(() => alert('Code copied to clipboard!'))
//               .catch(err => console.error('Failed to copy code: ', err));
//           };
  
//           // Append the button inside the wrapper
//           wrapper.appendChild(copyButton);
//         }
//       });
//     }
//   }, [notes]);
  
  
  
 
//   useEffect(() => {
//     setLoading(true);
//     adminaxios
//       .get(`/modules/${selectedModuleId}/`)
//       .then((response) => {
//         const moduleDetails = response.data?.data?.module_details;
//         if (moduleDetails && Array.isArray(moduleDetails) && moduleDetails.length > 0) {
//           const topics = moduleDetails[0].topics;
//           if (Array.isArray(topics) && topics.length > 0) {
//             const initialTopicId = topics[0]._id;
//             dispatch(setTopicId(initialTopicId));
//           } else {
//             console.error('No topics found in module details.');
//           }
//         } else {
//           console.error('No module details found or module details is empty.');
//         }
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//       });
//   }, [selectedModuleId, dispatch]);
 
//   useEffect(() => {
//     if (storeNotesId !== null) {
//       setLoading(true);
//       adminaxios
//         .get(`/contents/${storeNotesId}/`)
//         .then((response) => {
//           const data = response.data;
//           setNotesTitle(data.versions[0]?.title || '');
//           const contentHtml = data.versions[0]?.data || '';
 
//           // Parse the HTML content to exclude the first h1 element
//           const parser = new DOMParser();
//           const doc = parser.parseFromString(contentHtml, 'text/html');
//           const headingsList = [];
//           const contentWithoutFirstH1 = [];
//           let firstH1Removed = false;
 
//           doc.querySelectorAll('h1, h2, h3, h4').forEach((heading, index) => {
//             const id = `heading-${index}`;
//             heading.setAttribute('id', id); // Assign ID to each heading directly in the DOM
 
//             if (heading.tagName === 'H1' && !firstH1Removed) {
//               // Skip the first h1 and mark it as removed
//               firstH1Removed = true;
//             } else {
//               headingsList.push({
//                 id,
//                 text: heading.innerText.replace(/:/g, ''), // Store other headings
//                 tag: heading.tagName,
//               });
//               contentWithoutFirstH1.push(heading.outerHTML);
//             }
 
//             let nextElement = heading.nextElementSibling;
//             while (nextElement && !['H1', 'H2', 'H3'].includes(nextElement.tagName)) {
//               contentWithoutFirstH1.push(nextElement.outerHTML);
//               nextElement = nextElement.nextElementSibling;
//             }
//           });
 
//           setHeadings(headingsList);
 
//           // Set the modified content excluding only the first h1
//           setNotes(contentWithoutFirstH1.join(''));
//           setLoading(false);
//         })
//         .catch((error) => {
//           setLoading(false);
//           console.error('Error fetching the data:', error);
//         });
//     }
//   }, [storeNotesId]);
 
 
//   useEffect(() => {
//     // Adding a slight delay to ensure the content is rendered before any scroll action
//     const timeoutId = setTimeout(() => {
//       if (activeTab) {
//         handleScrollTo(activeTab);
//       }
//     }, 50); // Adjust delay as needed
 
//     return () => clearTimeout(timeoutId);
//   }, [notes, activeTab]);
 
//   const handleNotesSkip = () => {
//     // Your handleNotesSkip function logic here
//   };
 
//   return (
//     <div style={{display:'flex',justifyContent:'flex-start'}}>
//       <Sidebar2 courseId={courseId} />
    
//     <div className="notes-app">
//       <div className="notes-content-wrapper">
//         {/* Sidebar and Search Container */}
//         <div className="notes-sidebar-container">
//           {/* Sidebar */}
//           <div className="notes-sidebar">
//             <div className="notes-search-bar">
//               <div className="search-container">
//                 <input
//                   type="text"
//                   placeholder="Search topics..."
//                   value={searchTerm}
//                   onChange={(e) => setSearchTerm(e.target.value)}
//                 />
//                 <Search className="basic-header-search-icon" />
//               </div>
//             </div>
//             <ul>
//               {filteredHeadings.map((heading, index) => (
//                 <li
//                   key={heading.id}
//                   className={`notes-topic-link ${activeTab === heading.id ? 'active' : ''} ${index === 0 ? 'first-heading' : ''}`}
//                   onClick={() => handleScrollTo(heading.id)}
//                 >
//                   <img
//                     src={filledbubble}
//                     alt="Unfilled Bubble"
//                     className="unfilledbubble"
//                   />
//                   {/* Image for filled bubble */}
//                   <img
//                     src={unfilledbubble}
//                     alt="Filled Bubble"
//                     className="filledbubble"
//                   />
 
//                   {heading.text}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>
 
//         {/* Main Content */}
//         <div className="notes-content">
//           <div className="notes-header">
//             <h1>{notesTitle || 'Loading...'}</h1>
//             <div className="notes-tags">
//               <span># lorem</span>
//               <span># ipsum</span>
//               <span># simply</span>
//               <span># dummy</span>
//             </div>
//           </div>
//           <div className="notes-title-btn-container">
//             <h2 className="notes-main-title"></h2>
//           </div>
//           <div
//             className="notes"
//             dangerouslySetInnerHTML={{ __html: notes }}
//           />
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// }
 
// export default Notes;



// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { adminaxios } from '../axios';
// import { useParams } from 'react-router-dom';
// import { setTopicId } from '../actions/apisIdsActions';
// import Prism from 'prismjs';
// import 'prismjs/themes/prism-okaidia.css'; // Choose any theme of your choice
// import 'prismjs/plugins/autoloader/prism-autoloader'; // Autoloader for dynamic languages
// import '../assets/Css/notes.css';
// import filledbubble from '../assets/Images/Ellipse 648.png';
// import unfilledbubble from '../assets/Images/Ellipse 651.png';
// import copyicon from '../assets/Images/document-copy.png';
// import { Search } from 'react-feather';
// import Sidebar2 from '../Sidebar/Sidebar2'; 
// import Animations from '../CourseList/RolebasedDetails/RolebasedModuleSkeleton';

// // Configure the autoloader to load languages dynamically
// Prism.plugins.autoloader.languages_path = 'https://cdnjs.cloudflare.com/ajax/libs/prism/1.28.0/components/';

// function Notes() {
//   const [activeTab, setActiveTab] = useState(null);
//   const [notesTitle, setNotesTitle] = useState('');
//   const [notes, setNotes] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [headings, setHeadings] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
//   const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
//   const dispatch = useDispatch();
//   const { id: courseId } = useParams();

//   const handleScrollTo = (id) => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//       element.style.paddingTop = '20px';
//       element.style.transition = 'padding 0.3s ease-in-out';
//       setActiveTab(id);
//     } else {
//       console.error(`No element found with ID ${id}`);
//     }
//   };

//   const filteredHeadings = headings.filter(heading =>
//     heading.text.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   useEffect(() => {
//     if (notes) {
//       const preElements = document.querySelectorAll('.notes pre');
//       preElements.forEach((pre) => {
//         const codeBlock = pre.querySelector('code');
//         // Add default language class if none exists
//         if (!codeBlock.classList.contains('language-')) {
//           codeBlock.classList.add('language-javascript'); // Default to JavaScript
//         }
  
//         if (!pre.parentNode.classList.contains('pre-wrapper')) {
//           const wrapper = document.createElement('div');
//           wrapper.classList.add('pre-wrapper');
//           pre.parentNode.insertBefore(wrapper, pre);
//           wrapper.appendChild(pre);
  
//           // Create and insert the "Copy Code" button
//           const copyButton = document.createElement('button');
//           copyButton.classList.add('copy-code-btn');
//           copyButton.innerHTML = `<img src="${copyicon}" alt="Copy Icon" width="16" height="16" /> Copy Code`;
        
//           copyButton.onclick = () => {
//             const code = pre.innerText;
//             navigator.clipboard.writeText(code)
//               .then(() => {
//                 copyButton.innerText = 'Copied!';
//                 setTimeout(() => {
//                   copyButton.innerHTML = `<img src="${copyicon}" alt="Copy Icon" width="16" height="16" /> Copy Code`;
//                 }, 3000); // 3 seconds delay
//               })
//               .catch(err => console.error('Failed to copy code: ', err));
//           };
  
//           wrapper.appendChild(copyButton);
//         }
//       });
  
//       // Apply Prism.js syntax highlighting
//       Prism.highlightAll();
//     }
//   }, [notes]); // Re-run when 'notes' content changes
  

//   useEffect(() => {
//     setLoading(true);
//     adminaxios
//       .get(`/modules/${selectedModuleId}/`)
//       .then((response) => {
//         const moduleDetails = response.data?.data?.module_details;
//         setLoading(false);
//         if (moduleDetails && Array.isArray(moduleDetails) && moduleDetails.length > 0) {
//           const topics = moduleDetails[0].topics;
//           if (Array.isArray(topics) && topics.length > 0) {
//             const initialTopicId = topics[0]._id;
//             dispatch(setTopicId(initialTopicId));
//           } else {
//             console.error('No topics found in module details.');
//             setLoading(false);
//           }
//         } else {
//           console.error('No module details found or module details is empty.');
//           setLoading(false);
//         }
        
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//       });
//   }, [selectedModuleId, dispatch]);

//   useEffect(() => {
//     if (storeNotesId !== null) {
//       setLoading(true);
//       adminaxios
//         .get(`/contents/${storeNotesId}/`)
//         .then((response) => {
//           const data = response.data;
//           setNotesTitle(data.versions[0]?.title || '');
//           const contentHtml = data.versions[0]?.data || '';

//           const parser = new DOMParser();
//           const doc = parser.parseFromString(contentHtml, 'text/html');
//           const headingsList = [];
//           const contentWithoutFirstH1 = [];
//           let firstH1Removed = false;

//           doc.querySelectorAll('h1, h2, h3, h4').forEach((heading, index) => {
//             const id = `heading-${index}`;
//             heading.setAttribute('id', id);

//             if (heading.tagName === 'H1' && !firstH1Removed) {
//               firstH1Removed = true;
//             } else {
//               headingsList.push({
//                 id,
//                 text: heading.innerText.replace(/:/g, ''),
//                 tag: heading.tagName,
//               });
//               contentWithoutFirstH1.push(heading.outerHTML);
//             }

//             let nextElement = heading.nextElementSibling;
//             while (nextElement && !['H1', 'H2', 'H3'].includes(nextElement.tagName)) {
//               contentWithoutFirstH1.push(nextElement.outerHTML);
//               nextElement = nextElement.nextElementSibling;
//             }
//           });

//           setHeadings(headingsList);
//           setNotes(contentWithoutFirstH1.join(''));
//           setLoading(false);
//         })
//         .catch((error) => {
//           setLoading(false);
//           console.error('Error fetching the data:', error);
//         });
//     }
//   }, [storeNotesId]);

//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       if (activeTab) {
//         handleScrollTo(activeTab);
//       }
//     }, 50);

//     return () => clearTimeout(timeoutId);
//   }, [notes, activeTab]);

//   const handleNotesSkip = () => {
//     // Handle skip logic here
//   };

//   if (loading) {
//     return <Animations />;
//   }

//   return (
//     <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
//       <Sidebar2 courseId={courseId} />

//       <div className="notes-app">
//         <div className="notes-content-wrapper">
//           {/* Sidebar and Search Container */}
//           <div className="notes-sidebar-container">
//             <div className="notes-sidebar">
//               <div className="notes-search-bar">
//                 <div className="search-container">
//                   <input
//                     type="text"
//                     placeholder="Search topics..."
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                   />
//                   <Search className="basic-header-search-icon" />
//                 </div>
//               </div>
//               <ul>
//                 {filteredHeadings.map((heading, index) => (
//                   <li
//                     key={heading.id}
//                     className={`notes-topic-link ${activeTab === heading.id ? 'active' : ''} ${index === 0 ? 'first-heading' : ''}`}
//                     onClick={() => handleScrollTo(heading.id)}
//                   >
//                     <img
//                       src={filledbubble}
//                       alt="Unfilled Bubble"
//                       className="unfilledbubble"
//                     />
//                     <img
//                       src={unfilledbubble}
//                       alt="Filled Bubble"
//                       className="filledbubble"
//                     />
//                     {heading.text}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>

//           {/* Main Content */}
//           <div className="notes-content">
//             <div className="notes-header">
//               <h1>{notesTitle || 'Loading...'}</h1>
//               <div className="notes-tags">
//                 <span># lorem</span>
//                 <span># ipsum</span>
//                 <span># simply</span>
//                 <span># dummy</span>
//               </div>
//             </div>
//             <div className="notes-title-btn-container">
//               <h2 className="notes-main-title"></h2>
//             </div>
//             <div
//               className="notes"
//               dangerouslySetInnerHTML={{ __html: notes }}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Notes;


import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { adminaxios } from '../axios';
import { useParams } from 'react-router-dom';
import { setTopicId } from '../actions/apisIdsActions';
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css'; // Choose any theme of your choice
import 'prismjs/plugins/autoloader/prism-autoloader'; // Autoloader for dynamic languages
import '../assets/Css/notes.css';
import filledbubble from '../assets/Images/Ellipse 648.png';
import unfilledbubble from '../assets/Images/Ellipse 651.png';
import copyicon from '../assets/Images/document-copy.png';
import { Search } from 'react-feather';
import Sidebar2 from '../Sidebar/Sidebar2';
import Animations from '../CourseList/RolebasedDetails/RolebasedModuleSkeleton'; // Import the Animations component

// Configure the autoloader to load languages dynamically
Prism.plugins.autoloader.languages_path = 'https://cdnjs.cloudflare.com/ajax/libs/prism/1.28.0/components/';

function Notes() {
  const [activeTab, setActiveTab] = useState(null);
  const [notesTitle, setNotesTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [headings, setHeadings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const dispatch = useDispatch();
  const { id: courseId } = useParams();

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      element.style.paddingTop = '20px';
      element.style.transition = 'padding 0.3s ease-in-out';
      setActiveTab(id);
    } else {
      console.error(`No element found with ID ${id}`);
    }
  };

  const filteredHeadings = headings.filter(heading =>
    heading.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (notes) {
      const preElements = document.querySelectorAll('.notes pre');
      preElements.forEach((pre) => {
        const codeBlock = pre.querySelector('code');
        // Add default language class if none exists
        if (!codeBlock.classList.contains('language-')) {
          codeBlock.classList.add('language-javascript'); // Default to JavaScript
        }

        if (!pre.parentNode.classList.contains('pre-wrapper')) {
          const wrapper = document.createElement('div');
          wrapper.classList.add('pre-wrapper');
          pre.parentNode.insertBefore(wrapper, pre);
          wrapper.appendChild(pre);

          // Create and insert the "Copy Code" button
          const copyButton = document.createElement('button');
          copyButton.classList.add('copy-code-btn');
          copyButton.innerHTML = `<img src="${copyicon}" alt="Copy Icon" width="16" height="16" /> Copy Code`;

          copyButton.onclick = () => {
            const code = pre.innerText;
            navigator.clipboard.writeText(code)
              .then(() => {
                copyButton.innerText = 'Copied!';
                setTimeout(() => {
                  copyButton.innerHTML = `<img src="${copyicon}" alt="Copy Icon" width="16" height="16" /> Copy Code`;
                }, 3000); // 3 seconds delay
              })
              .catch(err => console.error('Failed to copy code: ', err));
          };

          wrapper.appendChild(copyButton);
        }
      });

      // Apply Prism.js syntax highlighting
      Prism.highlightAll();
    }
  }, [notes]); // Re-run when 'notes' content changes

  useEffect(() => {
    setLoading(true);
    adminaxios
      .get(`/modules/${selectedModuleId}/`)
      .then((response) => {
        const moduleDetails = response.data?.data?.module_details;
        setLoading(false);
        if (moduleDetails && Array.isArray(moduleDetails) && moduleDetails.length > 0) {
          const topics = moduleDetails[0].topics;
          if (Array.isArray(topics) && topics.length > 0) {
            const initialTopicId = topics[0]._id;
            dispatch(setTopicId(initialTopicId));
          } else {
            console.error('No topics found in module details.');
            setLoading(false);
          }
        } else {
          console.error('No module details found or module details is empty.');
          setLoading(false);
        }

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [selectedModuleId, dispatch]);

  useEffect(() => {
    if (storeNotesId !== null) {
      setLoading(true);
      adminaxios
        .get(`/contents/${storeNotesId}/`)
        .then((response) => {
          const data = response.data;
          setNotesTitle(data.versions[0]?.title || '');
          const contentHtml = data.versions[0]?.data || '';

          const parser = new DOMParser();
          const doc = parser.parseFromString(contentHtml, 'text/html');
          const headingsList = [];
          const contentWithoutFirstH1 = [];
          let firstH1Removed = false;

          doc.querySelectorAll('h1, h2, h3, h4').forEach((heading, index) => {
            const id = `heading-${index}`;
            heading.setAttribute('id', id);

            if (heading.tagName === 'H1' && !firstH1Removed) {
              firstH1Removed = true;
            } else {
              headingsList.push({
                id,
                text: heading.innerText.replace(/:/g, ''),
                tag: heading.tagName,
              });
              contentWithoutFirstH1.push(heading.outerHTML);
            }

            let nextElement = heading.nextElementSibling;
            while (nextElement && !['H1', 'H2', 'H3'].includes(nextElement.tagName)) {
              contentWithoutFirstH1.push(nextElement.outerHTML);
              nextElement = nextElement.nextElementSibling;
            }
          });

          setHeadings(headingsList);
          setNotes(contentWithoutFirstH1.join(''));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error fetching the data:', error);
        });
    }
  }, [storeNotesId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (activeTab) {
        handleScrollTo(activeTab);
      }
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [notes, activeTab]);

  const handleNotesSkip = () => {
    // Handle skip logic here
  };


  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {/* <Sidebar2 courseId={courseId} /> */}

      <div className="notes-app">
        <div className="notes-content-wrapper">
          {/* Sidebar and Search Container */}
          <div className="notes-sidebar-container">
            <div className="notes-sidebar">
              <div className="notes-search-bar">
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search topics..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="basic-header-search-icon" />
                </div>
              </div>
              
              <ul>
                {filteredHeadings.map((heading, index) => (
                  <li
                    key={heading.id}
                    className={`notes-topic-link ${activeTab === heading.id ? 'active' : ''} ${index === 0 ? 'first-heading' : ''}`}
                    onClick={() => handleScrollTo(heading.id)}
                  >
                    <img
                      src={filledbubble}
                      alt="Unfilled Bubble"
                      className="unfilledbubble"
                    />
                    <img
                      src={unfilledbubble}
                      alt="Filled Bubble"
                      className="filledbubble"
                    />
                    {heading.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Main Content */}
          <div className="notes-content">
            {loading ? (
              <Animations /> // Skeleton loader will display if data is loading
            ) : (
              <>
                <div className="notes-header">
                  <h1>{notesTitle || 'Loading...'}</h1>
                  <div className="notes-tags">
                    <span># lorem</span>
                    <span># ipsum</span>
                    <span># simply</span>
                    <span># dummy</span>
                  </div>
                </div>
                <div className="notes-title-btn-container">
                  <h2 className="notes-main-title"></h2>
                </div>
                <div
                  className="notes"
                  dangerouslySetInnerHTML={{ __html: notes }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;

