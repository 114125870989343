import React, { useState, useEffect } from 'react';
import '../assets/Css/Header1.css';
import homeIcon from '../assets/Images/sidebar_header_icon.png';
import Nextarrowicon from '../assets/Images/ArrowforNext.png';
import backarrowicon from '../assets/Images/leftarrow.png';
import hidePrev from '../assets/Images/hide_left.png';
import hideNext from '../assets/Images/hide_right.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logout from '../assets/Images/logout@2x.png';
import { setTopicId, setMediaContentId, setLabConfigId, setQuizConfigId, setNotesId, setVideoId, setPowerPointId, setTopicTitle, setModuleId, setModuleTitle, isAssesmentLabCondition, isAssesmentQuizCondition } from "../actions/apisIdsActions";
import { adminaxios } from '../axios';
import { useLocation } from 'react-router-dom';
// import { Tooltip } from 'react-tooltip';
import Tooltip from '@mui/material/Tooltip';
import { toggleSidebarRedux } from '../actions/sidebarActions';
import { courseType } from '../actions/typeOfCourseActions';


function Header1() {
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [modulesArrayData, setModulesArrayData] = useState([]);
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const courseId = useSelector((state) => state.dynamicApiId.courseId);
  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const dispatch = useDispatch();
  const location = useLocation();

  const courseTypeToggle = useSelector((state) => state.CourseType.courseType);
  const sidebarAction = useSelector((state) => state.sidebar.sideToggle);
  const CourseTitle = useSelector((state) => state.dynamicApiId.courseTitle);
  const moduleTitle = useSelector((state) => state.dynamicApiId.moduleTitle);
  const topicTitle = useSelector((state) => state.dynamicApiId.topicTitle);
  const assessmentCheckingQuiz = useSelector((state) => state.dynamicApiId.assesmentQuizCheck);
  const assessmentCheckingLab = useSelector((state) => state.dynamicApiId.assesmentLabCheck);
  const roleBasedCourseID = localStorage.getItem('role_based_courseId');
  const ProgressCircle = ({ progress }) => {
    const radius = 20;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
      <svg className="header1-progress-circle" width="50" height="50"> {/* Increased width/height for more space */}
        <circle
          className="header1-progress-background"
          cx="25"  // Adjusted to new center
          cy="25"  // Adjusted to new center
          r={radius * 0.75}  // Adjust radius to 75% of the new radius
          strokeWidth="3.5"
        />
        <circle
          className="header1-progress-value"
          cx="25"
          cy="25"
          r={radius * 0.75}
          strokeWidth="3.5"
          strokeDasharray={circumference * 0.75}
          strokeDashoffset={offset * 0.75}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".5em"  // Adjusted dy for vertical alignment, increasing the gap
          className="header1-progress-text"
          fontSize="10px"  // You can adjust this as per your need
        >
          {`${progress}%`}
        </text>
      </svg>
    );
  };



  useEffect(() => {
    setLoading(true);
    adminaxios
      .get(`/courses/${courseTypeToggle ? roleBasedCourseID : courseId}`)
      .then((response) => {
        const modules = response?.data?.data?.module_details;
        setModulesArrayData(modules);

        const selectedModule = modules?.find(module => module._id === selectedModuleId);
        if (selectedModule) {
          setTopicsArrayData(selectedModule.topics);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [courseId, selectedModuleId]);

  
  const handleToHome = () => {
    //setShowExitPopup(true);
    dispatch(toggleSidebarRedux());
  };

  const handleExitCourse = () => {
    navigate('/course-list');
  };

  const handleContinueCourse = () => {
    setShowExitPopup(false);
  };

  const courseTitleHandle = () => {
    localStorage.removeItem('role_based_courseId');
    if (courseTypeToggle) {
      navigate(`/rolebased-details/${roleBasedCourseID}`);
    } else {
      navigate(`/course-details/${courseId}`);
    }
  };


  const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const storeQuizConfigId = useSelector((state) => state.dynamicApiId.quizConfigId);
  const storeLabConfigId = useSelector((state) => state.dynamicApiId.labConfigId);
  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const storeVideoId = useSelector((state) => state.dynamicApiId.videoId)
  console.log('storeLabConfigId', storeLabConfigId);
  console.log(' storeNotesId ', storeNotesId);
  console.log('storeQuizConfigId', storeQuizConfigId);
  console.log('storeVideoId', storeVideoId);


  const topicsIndex = topicsArrayData?.map(topic => topic._id);
  const currentTopicIndex = topicsIndex?.indexOf(selectedTopicId);
  const modulesIndex = modulesArrayData?.map(module => module._id);
  const currentModuleIndex = modulesIndex?.indexOf(selectedModuleId);

  const hidePreviousImage =
    location.pathname.includes('/combine/') &&
    currentModuleIndex === 0 &&
    currentTopicIndex === 0;

  const hideNextImage =
    (location.pathname.includes('/lab-list/') && currentModuleIndex === modulesArrayData?.length - 1 && currentTopicIndex === topicsArrayData?.length - 1) ||
    (location.pathname.includes('/quiz/') && currentModuleIndex === modulesArrayData?.length - 1 && currentTopicIndex === topicsArrayData?.length - 1 && storeLabConfigId === null) ||
    (location.pathname.includes('/notes/') && currentModuleIndex === modulesArrayData?.length - 1 && currentTopicIndex === topicsArrayData?.length - 1 && storeLabConfigId === null && storeQuizConfigId === null) ||
    (location.pathname.includes('/combine/') && currentModuleIndex === modulesArrayData?.length - 1 && currentTopicIndex === topicsArrayData?.length - 1 && storeLabConfigId === null && storeQuizConfigId === null && storeNotesId === null);


  const hideBothImages = (location.pathname.includes('/quiz-page/')) || (location.pathname.includes('/quiz-results/'));

  const handleToNextTopicInCombine = () => {
    // Sequentially check for available IDs (videoId -> notesId -> quizId -> labId) and navigate accordingly

    if (location.pathname.includes('/combine/')) {
      // Currently on video, check if we have notesId to navigate to
      if (storeNotesId !== null) {
        navigate(`/notes/${courseId}`);
      } else if (storeQuizConfigId !== null) {
        navigate(assessmentCheckingQuiz ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (storeLabConfigId !== null) {
        navigate(`/lab-list/${courseId}`);

      } else {
        handleNextTopicDetails(); // If no other ID, go to the next topic
      }
    } else if (location.pathname.includes('/notes/')) {
      // Currently on notes, check if we have quizId to navigate to
      if (storeQuizConfigId !== null) {
        navigate(assessmentCheckingQuiz ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (storeLabConfigId !== null) {
        navigate(`/lab-list/${courseId}`);
      } else {
        handleNextTopicDetails(); // If no other ID, go to the next topic
      }
    } else if (location.pathname.includes('/quiz/') || location.pathname.includes('/timedquiz/')) {
      // Currently on quiz, check if we have labId to navigate to
      if (storeLabConfigId !== null) {
        navigate(`/lab-list/${courseId}`);
      } else {
        handleNextTopicDetails(); // If no labId, go to the next topic
      }
    } else if (location.pathname.includes('/lab-list/') || location.pathname.includes('/timed-assessment-lab/')) {
      // Currently on lab, move to the next topic or module
      handleNextTopicDetails();
    } else {
      // If none of the conditions match, directly call the next topic logic
      handleNextTopicDetails();
    }
  };


  const handleNextTopicDetails = () => {
    if (currentTopicIndex < topicsIndex?.length - 1) {
      // Get next topic details
      const nextTopic = topicsArrayData[currentTopicIndex + 1];
      const nextTopicId = nextTopic._id;
      const nextTopicTitle = nextTopic.title;
      const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
      const nextMediaContent = nextTopic.media_content_ids[0];
      const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setNotesId(nextNotesId || null));
      dispatch(setVideoId(nextVideoId || null));
      dispatch(setTopicId(nextTopicId || null));
      dispatch(setMediaContentId(nextMediaContentId || null));
      dispatch(setPowerPointId(nextPptId || null));
      dispatch(setTopicTitle(nextTopicTitle || null));

      let labId = '', quizId = '', assesmentQuizCheck = '', assesmentLabCheck = '';
      nextTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
          assesmentLabCheck = config.is_assessment;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
          assesmentQuizCheck = config.is_assessment;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));
      dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
      dispatch(isAssesmentLabCondition(assesmentLabCheck || null));

      // Navigate based on available IDs, starting with video
      if (nextVideoId) {
        navigate(`/combine/${courseId}`);
      } else if (nextNotesId) {
        navigate(`/notes/${courseId}`);
      } else if (quizId) {
        navigate(assesmentQuizCheck ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (labId) {
        navigate(`/lab-list/${courseId}`);
      } else {
        // If none of the content is found, move to the next topic
        handleNextTopicDetails();
      }
    } else if (currentTopicIndex === topicsIndex?.length - 1 && currentModuleIndex < modulesIndex?.length - 1) {
      // Move to the next module if the current topic index is exhausted
      const nextModule = modulesArrayData[currentModuleIndex + 1];
      const nextModuleId = nextModule._id;
      const nextModuleTitle = nextModule.title;
      const nextTopic = nextModule.topics[0];
      const nextTopicId = nextTopic._id;
      const nextTopicTitle = nextTopic.title;
      const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
      const nextMediaContent = nextTopic.media_content_ids[0];
      const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setModuleId(nextModuleId || null));
      dispatch(setModuleTitle(nextModuleTitle || null));
      dispatch(setNotesId(nextNotesId || null));
      dispatch(setVideoId(nextVideoId || null));
      dispatch(setTopicId(nextTopicId || null));
      dispatch(setMediaContentId(nextMediaContentId || null));
      dispatch(setPowerPointId(nextPptId || null));
      dispatch(setTopicTitle(nextTopicTitle || null));

      let labId = '', quizId = '', assesmentQuizCheck = '', assesmentLabCheck = '';
      nextTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
          assesmentLabCheck = config.is_assessment;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
          assesmentQuizCheck = config.is_assessment;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));
      dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
      dispatch(isAssesmentLabCondition(assesmentLabCheck || null));

      // Navigate based on available IDs for the new module's first topic
      if (nextVideoId) {
        navigate(`/combine/${courseId}`);
      } else if (nextNotesId) {
        navigate(`/notes/${courseId}`);
      } else if (quizId) {
        navigate(assesmentQuizCheck ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (labId) {
        navigate(`/lab-list/${courseId}`);
      }
    }
  };

  const handleToPreviousTopicInCombine = () => {
    // Check current path and navigate to the previous content in the sequence
    if (location.pathname.includes('/lab-list/') || location.pathname.includes('/timed-assessment-lab/')) {
      if (storeQuizConfigId !== null) {
        navigate(assessmentCheckingQuiz ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (storeNotesId !== null) {
        navigate(`/notes/${courseId}`);
      } else if (storeVideoId !== null) {
        navigate(`/combine/${courseId}`);
      } else {
        handlePreviousTopicDetails(); // Go to the previous topic/module if no IDs are available
      }
    } else if (location.pathname.includes('/quiz/') || location.pathname.includes('/timedquiz/')) {
      if (storeNotesId !== null) {
        navigate(`/notes/${courseId}`);
      } else if (storeVideoId !== null) {
        navigate(`/combine/${courseId}`);
      } else {
        handlePreviousTopicDetails();
      }
    } else if (location.pathname.includes('/notes/')) {
      if (storeVideoId !== null) {
        navigate(`/combine/${courseId}`);
      } else {
        handlePreviousTopicDetails();
      }
    } else if (location.pathname.includes('/combine/')) {
      handlePreviousTopicDetails(); // Go to the previous topic/module when no videoId is available
    }
  };

  const handlePreviousTopicDetails = () => {
    // If there are more topics before the current one, navigate to the previous topic
    if (currentTopicIndex > 0) {
      const previousTopic = topicsArrayData[currentTopicIndex - 1];
      const previousTopicId = previousTopic._id;
      const previousTopicTitle = previousTopic.title;
      const previousMediaContentId = previousTopic.media_content_ids[0]?._id;
      const previousMediaContent = previousTopic.media_content_ids[0];
      const previousVideoId = previousMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const previousNotesId = previousMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const previousPptId = previousMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setNotesId(previousNotesId || null));
      dispatch(setVideoId(previousVideoId || null));
      dispatch(setTopicId(previousTopicId || null));
      dispatch(setMediaContentId(previousMediaContentId || null));
      dispatch(setPowerPointId(previousPptId || null));
      dispatch(setTopicTitle(previousTopicTitle || null));

      let labId = '', quizId = '', assesmentQuizCheck = '', assesmentLabCheck = '';
      previousTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
          assesmentLabCheck = config.is_assessment;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
          assesmentQuizCheck = config.is_assessment;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));
      dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
      dispatch(isAssesmentLabCondition(assesmentLabCheck || null));

      // Navigate based on available IDs for the previous topic
      if (labId) {
        navigate(`/lab-list/${courseId}`);
      } else if (quizId) {
        navigate(assesmentQuizCheck ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (previousNotesId) {
        navigate(`/notes/${courseId}`);
      } else if (previousVideoId) {
        navigate(`/combine/${courseId}`);
      }
    } else if (currentTopicIndex === 0 && currentModuleIndex > 0) {
      // If at the first topic of a module, move to the last topic of the previous module
      const previousModule = modulesArrayData[currentModuleIndex - 1];
      const previousModuleId = previousModule._id;
      const previousModuleTitle = previousModule.title;
      const lastTopic = previousModule.topics[previousModule.topics?.length - 1];
      const lastTopicId = lastTopic._id;
      const lastTopicTitle = lastTopic.title;
      const lastMediaContentId = lastTopic.media_content_ids[0]?._id;
      const lastMediaContent = lastTopic.media_content_ids[0];
      const lastVideoId = lastMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
      const lastNotesId = lastMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
      const lastPptId = lastMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;

      dispatch(setModuleId(previousModuleId || null));
      dispatch(setModuleTitle(previousModuleTitle || null));
      dispatch(setNotesId(lastNotesId || null));
      dispatch(setVideoId(lastVideoId || null));
      dispatch(setTopicId(lastTopicId || null));
      dispatch(setMediaContentId(lastMediaContentId || null));
      dispatch(setPowerPointId(lastPptId || null));
      dispatch(setTopicTitle(lastTopicTitle || null));

      let labId = '', quizId = '', assesmentQuizCheck = '', assesmentLabCheck = '';
      lastTopic.question_bank_configs.forEach(config => {
        if (config.type === 'Lab') {
          labId = config._id;
          assesmentLabCheck = config.is_assessment;
        } else if (config.type === 'Quiz') {
          quizId = config._id;
          assesmentQuizCheck = config.is_assessment;
        }
      });

      dispatch(setLabConfigId(labId ? labId : null));
      dispatch(setQuizConfigId(quizId ? quizId : null));
      dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
      dispatch(isAssesmentLabCondition(assesmentLabCheck || null));

      // Navigate based on available IDs for the last topic in the previous module
      if (labId) {
        navigate(`/lab-list/${courseId}`);
      } else if (quizId) {
        navigate(assesmentQuizCheck ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
      } else if (lastNotesId) {
        navigate(`/notes/${courseId}`);
      } else if (lastVideoId) {
        navigate(`/combine/${courseId}`);
      }
    }
  };


  return (
    <div className="header1-container">
      <div className="header1-left">
        <img src={homeIcon} alt="Home" className="header1-home-icon" onClick={handleToHome} />
        <div className="header1-divider"></div>
        <div className="header1-text">
          <h4 className="header1-title" onClick={courseTitleHandle}>{CourseTitle}</h4>
          {/* <p className="header1-subtitle">{`${moduleTitle}`} / <span>{`${topicTitle}`}</span></p> */}
          <Tooltip title={`${moduleTitle} / ${topicTitle}`} arrow>
            {/* <p className="header1-subtitle">
        {`${moduleTitle}`} / <span>{`${topicTitle}`}</span>
      </p> */}
            <p className="header1-subtitle">
              <span className="header1-module-title">{`${moduleTitle}`}</span>
              / <span>{`${topicTitle}`}</span>
            </p>

          </Tooltip>
        </div>
      </div>

      <div className="header1-center">
        {!hideBothImages && (
          <>
            {!hidePreviousImage ? (
              <img src={backarrowicon} alt="Back" className="header1-prev_and_next" onClick={handleToPreviousTopicInCombine} />
            ) : (
              <img src={hidePrev} alt='back' className="header1-prev_and_next_hide" />
            )}



            {!hideNextImage ? (
              <img src={Nextarrowicon} alt="Next" className="header1-prev_and_next" onClick={handleToNextTopicInCombine} />
            ) : (
              <img src={hideNext} alt='back' className="header1-prev_and_next_hide" />
            )}
          </>
        )}
      </div>
      <div className='header1-progress-container'>
        <div className='header1-last'>
          {/* <div className="header1-text-two">
          <h4 className="header1-course-title">Course Progress</h4>
          <progress id="file" value="20" max="100" className='header1-progress' ></progress>
          <p className="header1-totalprogress">Total In %</p>
        </div> */}
          <div className="header1-text-two">
            <h4 className="header1-course-title">Course Progress</h4>
            <div className="header1-progressbar">
              <div
                className="header1_progress-fill"
                style={{ width: '70%' }} // Adjust the progress percentage here
              ></div>
            </div>
          </div>
          <div className="header1-divider"></div>
          <div className="header1-right">
            {/* <ProgressCircle progress={20} /> */}
            <h3>20%</h3>
          </div>

        </div>

      </div>



      {showExitPopup && (
        <div className="course-exit-popup-container">
          <div className="course-exit-popup">
            <p>Are you sure, you want to exit the Course?</p>
            <div className="course-exit-buttons">
              <button className='course-exit-quiz-button' onClick={handleExitCourse}>Exit Course</button>
              <button className='course-exit-continue-button' onClick={handleContinueCourse}>Continue Course</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header1;