// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import '../assets/Css/Signin.css';
// import Circle1 from '../assets/Images/Ellipse_Full.png';
// import Circle2 from '../assets/Images/Ellipse_Half.png';
// import companyLogo from '../assets/Images/cloud_sync_company_logo.png';
// import eyeOpen from '../assets/Images/eye.png';
// import eyeClose from '../assets/Images/eye-slash.png';
// import { CircularProgress } from '@mui/material';
// import { loginaxios } from '../axios';
// import { setCredentials } from '../reducers/authSlice';




// const Login = () => {
//   const [togglePassword, setTogglePassword] = useState(false);
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [errorMsg,setErrorMsg] = useState(0)
//   const navigate = useNavigate(); 
//   const dispatch = useDispatch();


//   const loginWithJwt = async (username, password) => {
//     const data = { username, password };
//     setLoading(true);

//     try {
//       const response = await loginaxios.post('/login/', data, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });     
//       const { refresh, access, username, role } = response.data;

//       localStorage.setItem('tokens',access);
//       localStorage.setItem('refresh_token',refresh);
//       localStorage.setItem('user_name' , username);
//       localStorage.setItem('user_role', role)
//       dispatch(setCredentials({
//         refresh : refresh,
//         access : access,
//         username: username,
//         role: role,
//       }));

//       if (access) {
//           navigate('/course-list');
//       }
//     } catch (err) {
//       console.error('Login error:', err);
//       setErrorMsg(err.response.status)
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter' && email && password) {
//       loginWithJwt(email, password);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', handleKeyPress);

//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, [email, password]);

//   return (
//     <div className="login-container">
//       <div className="login-left">
//         <div className="login-logo">
//           <img src={companyLogo} alt="company-logo" />
//         </div>
//         <div className="login-description">
//           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
//         </div>
//       </div>
      
//       <div className="login-right">
//         <div className="login-circles">
//           <img src={Circle1} alt="Circle 1" className="login-circle login-circle1" />
//           <img src={Circle2} alt="Circle 2" className="login-circle login-circle2" />
//         </div>
//         <div className="login-box">
//           <h2>Sign In</h2>
//           <form onSubmit={(e) => { e.preventDefault(); loginWithJwt(email, password); }}>
//             <div className="login-form-group">
//               <input className="login-form-control" type="text" placeholder="Username" required onChange={e => setEmail(e.target.value)} value={email} />
//             </div>
//             <div className="login-form-group">
//               <div className="password-container">
//                 <input className="login-form-control" type={togglePassword ? "text" : "password"} placeholder="Password" required onChange={e => setPassword(e.target.value)} value={password} />
//                 <span className="login-pw-position" onClick={() => setTogglePassword(!togglePassword)}>
//                   <img src={togglePassword ? eyeOpen : eyeClose} alt="icon" className="login-eye-icon" />
//                 </span>
//               </div>
//             </div>
//             <div className="login-form-group">
//               <Link to="forgetPassword" className="login-forgot-password">Forgot password?</Link>
//             </div>
//             <button type="submit" className="login-button" disabled={loading}>
//               Sign In
//               {loading && <CircularProgress size={8} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} />}
//             </button>
//           </form>
//           <div className="login-signup-link">
//             Don't have an account? <Link to="/signup">Sign up</Link> now
//           </div>
//           {errorMsg === 401 ? <p style={{color:'red',fontSize:'12px',fontWeight:'500'}}>Please Check Your Credentials</p>: ''}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;




















import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../assets/Css/Signin.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import companyLogo from '../assets/Images/cloud_sync_company_logo.png';
import eyeOpen from '../assets/Images/eye.png';
import eyeClose from '../assets/Images/eye-slash.png';
import { CircularProgress } from '@mui/material';
import { loginaxios } from '../axios';
import { setCredentials } from '../reducers/authSlice';

const Login = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate(); 
  const dispatch = useDispatch();

  const loginWithJwt = async (username, password) => {
    const data = { username, password };
    setLoading(true);
    setErrorMsg({ username: '', password: '' }); // Clear previous error messages

    try {
      const response = await loginaxios.post('/login/', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });     
      const { refresh, access, username, role } = response.data;

      localStorage.setItem('tokens', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('user_name', username);
      localStorage.setItem('user_role', role);
      dispatch(setCredentials({
        refresh,
        access,
        username,
        role,
      }));

      if (access) {
        navigate('/course-list');
      }
    } catch (err) {
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        setErrorMsg({
          username: errorData.username ? errorData.username[0] : '',
          password: errorData.password ? errorData.password[0] : ''
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && email && password) {
      loginWithJwt(email, password);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [email, password]);

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-logo">
          <img src={companyLogo} alt="company-logo" />
        </div>
        <div className="login-description">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
      </div>
      
      <div className="login-right">
        <div className="login-circles">
          <img src={Circle1} alt="Circle 1" className="login-circle login-circle1" />
          <img src={Circle2} alt="Circle 2" className="login-circle login-circle2" />
        </div>
        <div className="login-box">
          <h2>Sign In</h2>
          <form onSubmit={(e) => { e.preventDefault(); loginWithJwt(email, password); }}>
            <div className="login-form-group">
              <input 
                className="login-form-control" 
                type="text" 
                placeholder="Username" 
                required 
                onChange={e => setEmail(e.target.value)} 
                value={email} 
              />
            </div>
            <div className="login-form-group">
              <div className="password-container">
                <input 
                  className="login-form-control" 
                  type={togglePassword ? "text" : "password"} 
                  placeholder="Password" 
                  required 
                  onChange={e => setPassword(e.target.value)} 
                  value={password} 
                />
                <span className="login-pw-position" onClick={() => setTogglePassword(!togglePassword)}>
                  <img src={togglePassword ? eyeOpen : eyeClose} alt="icon" className="login-eye-icon" />
                </span>
              </div>
            </div>
            <div className="login-form-group">
              <Link to="forgetPassword" className="login-forgot-password">Forgot password?</Link>
            </div>
            <button type="submit" className="login-button" disabled={loading}>
              Sign In
              {loading && <CircularProgress size={8} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} />}
            </button>
          </form>
          <div className="login-signup-link">
            Don't have an account? <Link to="/signup">Sign up</Link> now
          </div>
          {errorMsg.username && <p style={{color: 'red', fontSize: '12px'}}>{errorMsg.username}</p>}
          {errorMsg.password && <p style={{color: 'red', fontSize: '12px'}}>{errorMsg.password}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;









