import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'react-feather';
import '../assets/Css/Sidebar2.css';
import topicsIcon from '../assets/Images/topics-icon.png';
import notesIcon from '../assets/Images/sidebar_notes_icon.png'
import durationIcon from '../assets/Images/duration-icon.png';
import quizIcon from '../assets/Images/flask.png';
import nitesIcon from '../assets/Images/sidebar_book.png';
import labIcon from '../assets/Images/lightbulb.png';
import { toggleSidebarRedux } from '../actions/sidebarActions';
import { adminaxios } from '../axios';
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { setModuleId, setTopicId, setMediaContentId, setLabConfigId, setQuizConfigId, setModuleTitle, setTopicTitle, setNotesId, setVideoId, setPowerPointId , isAssesmentLabCondition , isAssesmentQuizCondition } from '../actions/apisIdsActions';

const Sidebar2 = ({ courseId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [expandedModuleId, setExpandedModuleId] = useState(null);
    const [courseDetails, setCourseDetails] = useState([]);
    const [activeTopicId, setActiveTopicId] = useState(null);
    const storeCourseId = useSelector((state)=> state.dynamicApiId.courseId);
    const openDefaultModuleId = useSelector((state) => state.dynamicApiId.moduleId);
    const openDefaultTopicId = useSelector((state) => state.dynamicApiId.topicId);
    //console.log(openDefaultModuleId,"openDefaultModuleId")
    //console.log(openDefaultTopicId,"openDefaultTopicId")
    const currentTopicMediaId = useSelector((state) => state.dynamicApiId.mediaContentId);
    const currentNotesId = useSelector((state) => state.dynamicApiId.notesId);
    const currentVideoId = useSelector((state) => state.dynamicApiId.videoId);
    const currentQuizId = useSelector((state) => state.dynamicApiId.quizId);
    const currentLabId = useSelector((state) => state.dynamicApiId.labId);
    const sidebarAction = useSelector((state) => state.sidebar.sideToggle);
    //const assessmentCheckingQuiz = useSelector((state) => state.dynamicApiId.assesmentQuizCheck);
    //const assessmentCheckingLab = useSelector((state) => state.dynamicApiId.assesmentLabCheck);
    const courseTypeToggle = useSelector((state)=>state.CourseType.courseType);
    console.log(courseTypeToggle,'courseTypeToggle');
    const dynamicCourseId = useSelector((state) => state.dynamicApiId.courseId);
    const roleBasedCourseID = localStorage.getItem('role_based_courseId');
    console.log('roleCourse',roleBasedCourseID);
    console.log('courseIdddd',courseId);
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(toggleSidebarRedux());
    };

    const toggleModule = (moduleId) => {
        if (expandedModuleId === moduleId) {
            setExpandedModuleId(null);
            localStorage.removeItem('expandedModuleId');
        } else {
            setExpandedModuleId(moduleId);
            const selectedModule = courseDetails.data.module_details.find(module => module._id === moduleId);
            if (selectedModule && selectedModule.topics.length > 0) {
                const firstTopic = selectedModule.topics[0];
                handleToTopicRender(firstTopic._id, firstTopic.title, firstTopic.media_content_ids[0]?._id, firstTopic.question_bank_configs, firstTopic.media_content_ids);
            }
            localStorage.setItem('expandedModuleId', moduleId);
            dispatch(setModuleId(moduleId));
        }
    };

    const activeCourseId = courseTypeToggle? roleBasedCourseID : courseId;

    console.log('sidebar_courseId',activeCourseId);
    console.log('sidebar_rolecourseId',roleBasedCourseID);
    console.log('sidebar_norcourseId',courseId);
    useEffect(() => {
        adminaxios.get(`/courses/${activeCourseId}/`)
            .then(response => {
                setCourseDetails(response?.data);

                const savedModuleId = localStorage.getItem('expandedModuleId');
                const savedTopicId = localStorage.getItem('activeTopicId');
                const roleFirstModuleId = localStorage.getItem('LocalModuleId');
                const roleFirstTopicId = localStorage.getItem('LocalTopicId');

                // Check for openDefaultModuleId to auto-expand the module
                const moduleIdToExpand = openDefaultModuleId || savedModuleId;

                if (moduleIdToExpand) {
                    setExpandedModuleId(moduleIdToExpand);
                    const moduleToExpand = response.data?.module_details?.find(module => module._id === moduleIdToExpand);
                    if (moduleToExpand && moduleToExpand.topics.length > 0) {
                        if (savedTopicId) {
                            const savedTopic = moduleToExpand.topics?.find(topic => topic._id === savedTopicId);
                            if (savedTopic) {
                                handleToTopicRender(savedTopic._id, savedTopic.title, savedTopic.media_content_ids[0]?._id, savedTopic.question_bank_configs, savedTopic.media_content_ids);
                            }
                        } else {
                            const firstTopic = moduleToExpand.topics[0];
                            handleToTopicRender(firstTopic._id, firstTopic.title, firstTopic.media_content_ids[0]?._id, firstTopic.question_bank_configs, firstTopic.media_content_ids);
                        }
                    }
                } else {
                    if (response.data?.module_details?.length > 0) {
                        const firstModule = response.data.module_details[0];
                        setExpandedModuleId(firstModule._id);
                        if (firstModule.topics.length > 0) {
                            const firstTopic = firstModule.topics[0];
                            handleToTopicRender(firstTopic._id, firstTopic.title, firstTopic.media_content_ids[0]?._id, firstTopic.question_bank_configs, firstTopic.media_content_ids);
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        if (openDefaultTopicId) {
            setActiveTopicId(openDefaultTopicId);
        }
    }, [courseId, openDefaultModuleId, openDefaultTopicId]);

    const handleToModuleRender = (moduleId, moduleTitle) => {
        dispatch(setModuleId(moduleId));
        dispatch(setModuleTitle(moduleTitle));
    };

    const handleToTopicRender = (topicId, topicTitle, contentId, questionBankConfigs, mediaContentIds) => {
        setActiveTopicId(topicId);
        localStorage.setItem('activeTopicId', topicId);
        dispatch(setTopicId(topicId));
        dispatch(setTopicTitle(topicTitle));
        dispatch(setMediaContentId(contentId));
    
        let labId = '', quizId = '', notesId = '', videoId = '', Pptid = '';
        let assesmentQuizCheck = '', assesmentLabCheck = '';
    
        // Extracting quiz and lab configs first
        questionBankConfigs.forEach(config => {
            if (config.type === 'Lab') {
                labId = config._id;
                assesmentLabCheck = config.is_assessment;
                dispatch(isAssesmentLabCondition(assesmentLabCheck || null));
            } else if (config.type === 'Quiz') {
                quizId = config._id;
                assesmentQuizCheck = config.is_assessment;
            }
        });
    
        // Extracting media content (video, notes, ppt) and prioritizing navigation in sequence
        mediaContentIds.forEach(mediaContent => {
            mediaContent.content_ids.forEach(content => {
                if (content.format === '.mp4') {
                    videoId = content.content_id;
                } else if (content.format === 'HTML') {
                    notesId = content.content_id;
                } else if (content.format === '.pptx') {
                    Pptid = content.content_id;
                }
            });
        });
    
        // Dispatch the extracted IDs
        dispatch(setLabConfigId(labId || null));
        dispatch(setQuizConfigId(quizId || null));
        dispatch(setNotesId(notesId || null));
        dispatch(setVideoId(videoId || null));
        dispatch(setPowerPointId(Pptid || null));
        dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
        dispatch(isAssesmentLabCondition(assesmentLabCheck || null));
    
        // Navigate in the specific order: first video, then notes, then quiz, then lab
        if (videoId) {
            navigate(`/combine/${courseId}`);
        } else if (notesId) {
            navigate(`/notes/${courseId}`);
        } else if (quizId) {
            navigate(assesmentQuizCheck ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
        } else if (labId) {
            navigate(`/lab-list/${courseId}`);
        }
    };
    
    return (
        <div className={`sidebar_2 ${!sidebarAction ? 'collapsed' : ''}`}>
            {/* <div className={isCollapsed ? 'sidebar2_toggle-btn-true' : 'sidebar2_toggle-btn'} onClick={toggleSidebar} style={{ right: isCollapsed ? '' : '-15px' }}>
                {isCollapsed ? <ChevronRight className="sidebar2_arrow-icon" color='#BBBBBB' /> : <ChevronLeft className="arrow-icon" />}
            </div> */}
            <div className="sidebar_2-inner">
                {courseDetails?.data?.module_details?.map((module, index) => (
                    <div key={module._id} className="sidebar_2-module" onClick={() => handleToModuleRender(module._id, module.title)}>
                        {isCollapsed &&
                            <Tooltip
                                id={`module-tooltip-${module._id}`}
                                place="right"
                                effect="solid"
                                content={module.title}
                            />
                        }
                        <div
                            className={`sidebar_2-module-header ${expandedModuleId === module._id ? 'expanded' : ''}`}
                            onClick={() => toggleModule(module._id)}
                            data-tooltip-id={`module-tooltip-${module._id}`}
                        >
                            <div className="sidebar_2-module-header-left">
                                <h2 className="sidebar2_heading_2">{isCollapsed ? `${'M'}${index + 1}` : `Module ${index + 1}. ${module.title}`}</h2>
                                {!isCollapsed && (
                                    <div className="sidebar_2-module-info">
                                        <img src={topicsIcon} alt="Topics Icon" className="sidebar_2-icon" />
                                        <span>{module.topics.length} Topics</span>
                                        <img src={durationIcon} alt="Duration Icon" className="sidebar_2-icon duration-icon" />
                                        <span>2 Hrs</span>
                                    </div>
                                )}
                            </div>
                            {!isCollapsed && (
                                <div className="sidebar_2-module-header-right">
                                    {expandedModuleId === module._id ? (
                                        <ChevronUp size={16} />
                                    ) : (
                                        <ChevronDown size={16} />
                                    )}
                                </div>
                            )}
                        </div>
                        {expandedModuleId === module._id && (
                            <div className={`sidebar_2-module-topics ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                                {module?.topics?.map((topic, index) => (
                                    <div
                                        key={topic._id}
                                        className={`sidebar_2-topic ${activeTopicId === topic._id ? 'active' : ''}`}
                                        onClick={() => handleToTopicRender(topic._id, topic.title, topic.media_content_ids[0]?._id, topic.question_bank_configs, topic.media_content_ids)}
                                        data-tooltip-id={`topic-tooltip-${topic._id}`}
                                    >
                                        {isCollapsed &&
                                            <Tooltip
                                                id={`topic-tooltip-${topic._id}`}
                                                place="right"
                                                effect="solid"
                                                content={topic.title}
                                            />
                                        }
                                        <div className='sidebar_2-topic_right'>
                                            <div className="sidebar_2-topic-info">
                                                <h3>{isCollapsed ? `${'- T'}${index + 1}` : `${index + 1}.  ${topic.title}`}</h3>
                                            </div>
                                            {isCollapsed ? '' :
                                                <progress value='60' max="100" className="sidebar_2-topic-status-progress"></progress>
                                            }
                                            <div className='sidebar_2-topic-icons-parent'>
                                                {!isCollapsed && (
                                                    <div className="sidebar_2-topic-icons">
                                                        { topic?.media_content_ids[0]?.content_ids?.some(each => each.format === '.mp4') &&
                                                            <div className='sidebar_2-each-icon-cont'>
                                                                <img
                                                                    src={topicsIcon}
                                                                    alt="Lab Icon"
                                                                    className="sidebar_2-topic-icon"
                                                                />
                                                                <p>60 min</p>
                                                            </div>
                                                        }
                                                        {topic?.media_content_ids[0]?.content_ids?.some(each => each.format === 'HTML')   &&
                                                            <div className='sidebar_2-each-icon-cont'>
                                                                <img
                                                                    src={notesIcon}
                                                                    alt="Lab Icon"
                                                                    className="sidebar_2-topic-icon"
                                                                />
                                                                <p>Notes</p>
                                                            </div>
                                                        }
                                                        {topic?.question_bank_configs?.some(config => config.type === 'Quiz') && (
                                                            <div className='sidebar_2-each-icon-cont'>
                                                                <img
                                                                    src={labIcon}
                                                                    alt="Quiz Icon"
                                                                    className="sidebar_2-topic-icon"
                                                                />
                                                                <p>Quiz</p>
                                                            </div>
                                                        )}
                                                        {topic?.question_bank_configs?.some(config => config.type === 'Lab') && (
                                                            <div className='sidebar_2-each-icon-cont'>
                                                                <img
                                                                    src={quizIcon}
                                                                    alt="Lab Icon"
                                                                    className="sidebar_2-topic-icon"
                                                                />
                                                                <p>Lab</p>
                                                            </div>
                                                        )}
                                                        
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar2;
