import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/Css/Quiz.css';
import Timer from '../assets/Images/Quiz-Timer.png';
import Question from '../assets/Images/Quiz-Question.png';
import { Oval } from 'react-loader-spinner';
import { adminaxios } from '../axios/index';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setTopicId, setMediaContentId, setQuizConfigId, setLabConfigId,
  setNotesId, setVideoId, setPowerPointId, setTopicTitle, setModuleId
} from '../actions/apisIdsActions';

import Sidebar2 from '../Sidebar/Sidebar2';


const Quiz = () => {
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState(null);
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const [modulesArrayData, setModulesArrayData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: courseId } = useParams();

  const dynamicModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const dynamicTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const dynamicQuizId = useSelector((state) => state.dynamicApiId.quizConfigId);
  //const storeLabConfigId = useSelector((state) => state.dynamicApiId.labConfigId);

  // console.log('test1',dynamicModuleId);
  // console.log('test2',dynamicTopicId);
  // console.log('test3',dynamicQuizId);

  

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await adminaxios.get(`/modules/${dynamicModuleId}/topics/${dynamicTopicId}/question_bank_config/${dynamicQuizId}/`);
        setQuizData(response.data.question_bank_configs);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [dynamicModuleId, dynamicTopicId, dynamicQuizId]);

  const topicsIndex = topicsArrayData.map((topic) => topic._id);
  const currentTopicIndex = topicsIndex.indexOf(dynamicTopicId);


 

  const startQuiz = () => {
    navigate(`/quiz-page/${courseId}`, { state: { quizData } });
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <Oval height={50} width={50} color="#000080" ariaLabel="loading" />
      </div>
    );
  }


  return (
   
    <div style={{display:'flex',justifyContent:'flex-start'}}>
    {/* <Sidebar2 courseId={courseId} /> */}
    <div className="zuiq-homepage-container">
    
      <div className="zuiq-homepage-box">
        <div className="zuiq-homepage-headerPage">
          <h2 className="zuiq-homepage-h2">What is Variables - Knowledge Check</h2>
          <div className="zuiq-homepage-info-wrapper">
            <div className="zuiq-homepage-info">
              <div className="zuiq-homepage-info-item">
                <img src={Timer} alt="No Time Limit" className="zuiq-homepage-icon" />
                <div className="zuiq-homepage-info-text">
                  <p className="zuiq-homepage-duration">Test Duration</p>
                  <p className="zuiq-homepage-value">No time limit</p>
                </div>
              </div>
              <div className="zuiq-homepage-info-item">
                <img src={Question} alt="Questions" className="zuiq-homepage-icon" />
                <div className="zuiq-homepage-info-text">
                  <p className="zuiq-homepage-questions">Questions</p>
                  <p className="zuiq-homepage-value">{quizData?.count}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="zuiq-homepage-instructions">
          <strong>Instructions</strong>
          <p className="zuiq-homepage-paragraph">{quizData?.instructions}</p>
        </div>
        <div className="zuiq-homepage-skills-wrapper">
          <div className="zuiq-homepage-skills-box">
            <span className="zuiq-homepage-skill">Skills</span>
            <span className="zuiq-homepage-skill-language">HTML</span>
            <span className="zuiq-homepage-skill-language">CSS</span>
            <span className="zuiq-homepage-skill-language">BootStrap</span>
            <span className="zuiq-homepage-skill-language">JavaSript</span>
          </div>
          <div className="zuiq-homepage-sub-skills-box">
            <span className="zuiq-homepage-sub-skill">Sub-skills</span>
            <span className="zuiq-homepage-skill-basic">Basics</span>
          </div>
        </div>
        <button className="zuiq-homepage-start-quiz-btn" onClick={startQuiz} disabled={!quizData}>
          Start quiz
        </button>
      </div>
    </div>
    </div>
  );
};

export default Quiz;
