// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';

// export default function Animations() {
//   return (
//     <Box sx={{ width: '64%', height:300 , marginBottom:'20px'}}>
//       <Skeleton />
//       <Skeleton animation="wave" />
//       <Skeleton animation={false} />
//     </Box>
//   );
// }


import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function Animations() {
  return (
    <Box sx={{ width: '64%', marginBottom: '20px', marginTop:'5%' }}>
      {/* Skeleton with custom height */}
      <Skeleton variant="rectangular" width="100%" height={45} sx={{ marginBottom: '20px' , borderRadius:'15px'}} />
      
      {/* Skeleton with wave animation */}
      <Skeleton variant="rectangular" width="100%" height={45} animation="wave" sx={{ marginBottom: '20px', borderRadius:'15px'}} />
      
      {/* Static Skeleton with animation */}
      <Skeleton variant="rectangular" width="100%" height={45} animation="wave" sx={{ marginBottom: '20px', borderRadius:'15px'}}  />
    </Box>
  );
}
