
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../assets/Css/QuizResults.css';
import gif_one from '../assets/Images/gif_one.gif';
import { setTopicId, setMediaContentId ,setQuizConfigId,setLabConfigId,setTopicTitle,setPowerPointId,setNotesId,setVideoId} from '../actions/apisIdsActions';
import { adminaxios } from '../axios';
import { useParams } from 'react-router-dom';


const QuizResults = () => {
  const location = useLocation();
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quizResults = location.state?.quizResults || JSON.parse(localStorage.getItem('quizResults')) || {
    totalQuestions: 0,
    correctAnswers: 0,
    incorrectAnswers: 0,
    unattemptedAnswers: 0
  };

  const { id: courseId } = useParams();
  const quizDataList = location.state?.quizDataList || JSON.parse(localStorage.getItem('quizDataList')) || [];

  const [animatedFillLength, setAnimatedFillLength] = useState(0);

  const radius = 120;
  const circumference = 2 * Math.PI * radius;
  const gap = circumference * 0.1;
  const fillLength = (quizResults.correctAnswers / quizResults.totalQuestions) * (circumference - gap);

  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);

  const storeLabConfigId = useSelector((state) => state.dynamicApiId.labConfigId);

  const topicsIndex = topicsArrayData.map((topic) => topic._id);
  const currentTopicIndex = topicsIndex.indexOf(selectedTopicId);

  console.log(topicsArrayData);
  useEffect(() => {
    adminaxios.get(`/modules/${selectedModuleId}/`)
      .then((response) => {
        setTopicsArrayData(response.data.topics);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedModuleId]);

  useEffect(() => {
    let currentLength = 0;
    const step = fillLength / 100; // Adjust the step size to control the speed of animation
    const interval = setInterval(() => {
      currentLength += step;
      if (currentLength >= fillLength) {
        currentLength = fillLength;
        clearInterval(interval);
      }
      setAnimatedFillLength(currentLength);
    }, 15); // Adjust the interval time to control the speed of animation

    return () => clearInterval(interval);
  }, [fillLength]);

  useEffect(() => {
    if (quizDataList.length > 0) {
      localStorage.setItem('quizDataList', JSON.stringify(quizDataList));
    }
  }, [quizDataList]);

  const handleContinueToLab = () => {
    console.log('Initial storeLabConfigId:', storeLabConfigId);
  
    if (storeLabConfigId === null) {
      if (currentTopicIndex < topicsIndex.length - 1) {
        const nextTopic = topicsArrayData[currentTopicIndex + 1];
        const nextTopicId = nextTopic._id;
        const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
        const nextMediaContent = nextTopic.media_content_ids[0];
        const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
        const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
        const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;
        const nextTopicTitle = nextTopic.title
        dispatch(setTopicTitle(nextTopicTitle));
        dispatch(setTopicId(nextTopicId));
        dispatch(setMediaContentId(nextMediaContentId));
        dispatch(setNotesId(nextNotesId));
        dispatch(setVideoId(nextVideoId));
        dispatch(setPowerPointId(nextPptId));
        let labId = '';
        let quizId = '';
        nextTopic.question_bank_configs.forEach(config => {
          if (config.type === 'Lab') {
            labId = config._id;
          } else if (config.type === 'Quiz') {
            quizId = config._id;
          }
        });
  
        console.log('Lab ID:', labId);
        console.log('Quiz ID:', quizId);
  
        if (labId) {
          dispatch(setLabConfigId(labId));
        } else {
          dispatch(setLabConfigId(null));
        }
  
        if (quizId) {
          dispatch(setQuizConfigId(quizId));
        } else {
          dispatch(setQuizConfigId(null));
        }
  
         if (nextVideoId) {
            navigate(`/combine/${courseId}`);
          } else if (nextNotesId) {
            navigate(`/notes/${courseId}`);
          } else if (quizId) {
            navigate(`/quiz/${courseId}`);
          } else if (labId) {
            navigate(`/lab-list/${courseId}`);
          }       
        
      } else {
        console.log('No more topics.');
      }
    } else {
      console.log('Navigating to:', `/lab-list/${courseId}`);
      navigate(`/lab-list/${courseId}`);
    }
  };

  const handleReAttempt = () => {
    if (quizDataList.length === 0) {
      console.error('No question IDs available');
      return;
    }

    console.log('Navigating with quizDataList:', quizDataList);
    navigate({
      pathname: `/quiz/${courseId}`,
      state: { reattempt: true, quizData: { question_bank_ids: quizDataList } }
    });
  };

  const formatNumber = (number) => {
    return number.toString().padStart(2, '0');
  };

  return (
    <div className="results-body">
      <div className="results-container">
        <div>
          <h3 className="results-quiz">Quiz results</h3>
          <div className="results-loader">
            <svg width="300" height="300">
              <circle
                className="results-circle-bg"
                cx="150"
                cy="150"
                r={radius}
                strokeDasharray={`${circumference - gap} ${gap}`}
                strokeDashoffset="0"
                strokeLinecap="round"
                transform="rotate(-90 150 150)"
              />
              <circle
                className="results-circle"
                cx="150"
                cy="150"
                r={radius}
                strokeDasharray={`${animatedFillLength} ${circumference - animatedFillLength} ${gap}`}
                strokeDashoffset="0"
                strokeLinecap="round"
                transform="rotate(-90 150 150)"
              />
              <line x1="110" y1="170" x2="150" y2="210" stroke="#000080" strokeWidth="5" strokeLinecap="round" />
              <line x1="150" y1="210" x2="210" y2="120" stroke="#000080" strokeWidth="5" strokeLinecap="round" />
            </svg>
            <div className="results-score-text">Your score {Math.round((quizResults.correctAnswers / quizResults.totalQuestions) * 100)}</div>
            <div className="total-score-text">
              Total Score<br />100
            </div>
          </div>
        </div>
        <div>
          {quizResults.correctAnswers >= 5 ?
            <div style={{ paddingTop: '20px', height: '250px', backgroundImage: `url(${gif_one})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <h1 className="quiz-results-cong-text">Congratulations!</h1>
              <p className="quiz-results-cong-msg">Wow, great job! You have completed the quiz successfully</p>
            </div>
            :
            <div style={{ paddingTop: '20px', height: '250px' }}>
              <h1 className="quiz-results-cong-text">Oops !</h1>
              <p className="quiz-results-cong-msg">Better luck next time</p>
            </div>
          }
          <div className='row'>
            <div className="results-details-container">
              <ul className="results-score-details">
                <li className="results-questions">
                  Number of questions <span>{formatNumber(quizResults.totalQuestions)}</span>
                </li>
                <li className="results-unattempted">
                  Unattempted <span>{formatNumber(quizResults.unattemptedAnswers)}</span>
                </li>
                <li className="results-correct">
                  Correct <span>{formatNumber(quizResults.correctAnswers)}</span>
                </li>
                <li className="results-incorrect">
                  Incorrect <span>{formatNumber(quizResults.incorrectAnswers)}</span>
                </li>
              </ul>

              <div className="results-buttons">
                <button className="reattempt-button" onClick={handleReAttempt}>Re-attempt</button>
                <button className="continue-button" onClick={handleContinueToLab}>{storeLabConfigId !== null ? 'Continue to lab' : "Next Topic"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResults;

