 
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import gif_one from '../assets/Images/gif_one.gif';
import axios from 'axios';
import '../assets/Css/QuizResults.css';
import { useDispatch } from 'react-redux';
import { adminaxios } from '../axios/index';
import { setTopicId, setMediaContentId, setLabConfigId, setQuizConfigId, setNotesId, setVideoId, setPowerPointId, setTopicTitle, setModuleId, setModuleTitle,isAssesmentLabCondition,isAssesmentQuizCondition } from "../actions/apisIdsActions";
import arrowleft from '../assets/Images/arrow-left.png';
import Animations from '../CourseList/RolebasedDetails/RolebasedModuleSkeleton';
 
const TimedQuizResults = () => {
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [modulesArrayData, setModulesArrayData] = useState([]);
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const roleBasedCourseID = localStorage.getItem('role_based_courseId');
  const courseTypeToggle = useSelector((state)=>state.CourseType.courseType);
  // Fallback if location.state is not available
  const { quizResults } = location.state || {
    quizResults: { total_questions: 0, correct_answers: 0, incorrect_answers: 0, unattempted_answers: 0 },
  };
  const [loading, setLoading] = useState(false);
  const [animatedFillLength, setAnimatedFillLength] = useState(0);
  const [reviewData, setReviewData] = useState(null); // State to store the review data
  const [showDrawer, setShowDrawer] = useState(false); // State to control the drawer
 
 
  const radius = 120;
  const circumference = 2 * Math.PI * radius;
  const gap = circumference * 0.1;
// Calculate the fill length based on correct answers / total questions ratio
const fillPercentage = (quizResults.correct_answers / quizResults.total_questions) * 100;
const fillLength = (fillPercentage / 100) * (circumference - gap); // Convert percentage to strokeDashoffset length
const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);
 
  useEffect(() => {
    // Animation for score progress
    let currentLength = 0;
    const step = fillLength / 100;
    const interval = setInterval(() => {
      currentLength += step;
      if (currentLength >= fillLength) {
        currentLength = fillLength;
        clearInterval(interval);
      }
      setAnimatedFillLength(currentLength);
    }, 15);
 
    return () => clearInterval(interval);
  }, [fillLength]);

  useEffect(() => {
    setLoading(true);
    adminaxios
      .get(`/courses/${courseTypeToggle?roleBasedCourseID:courseId}`)
      .then((response) => {
        const modules = response?.data?.data?.module_details;
        setModulesArrayData(modules);

        const selectedModule = modules?.find(module => module._id === selectedModuleId);
        if (selectedModule) {
          setTopicsArrayData(selectedModule.topics);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [courseId, selectedModuleId]);

  const topicsIndex = topicsArrayData?.map(topic => topic._id);
  const currentTopicIndex = topicsIndex?.indexOf(selectedTopicId);
  const modulesIndex = modulesArrayData?.map(module => module._id);
  const currentModuleIndex = modulesIndex?.indexOf(selectedModuleId);

 
  useEffect(() => {
    console.log("Review Data:", reviewData); // Add this to check if reviewData is updated correctly
  }, [reviewData]);
 
  // Function to get the token for authorization
  // const getToken = () => {
  //   return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI2NzY0NjY1LCJpYXQiOjE3MjY3MjE0NjUsImp0aSI6IjViYjg1ZGYxZTdmMzQwNjY4NzNiZGM4ZWZjM2IwNjMxIiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJsbXMxIiwicm9sZSI6IkFkbWluIn0.VDFy8OvEfFa1OPNNOgHqVqjvWtjHaOUZYLQz1jwp5gA'; // Replace with your actual token logic
  // };
 
  // Function to fetch review data
  const handleReview = async () => {
    setShowDrawer(true); 
    setTimeout(() => setLoading(true), 100);
    try {
      // Fetch the review data from your API
      const response = await adminaxios.get('/review_quiz/66ed3f2efa095c767878c348/', {
        // headers: {
        //   Authorization: `Bearer ${getToken()}`, // Use your token logic here
        // },
      });
 
      console.log("Review Data:", response.data); // Log the data to see it in the console
      setReviewData(response.data.review); // Assuming "review" contains the question data
      setShowDrawer(true); // Open the drawer after fetching data
 
    } catch (error) {
      console.error('Error fetching review data', error);
    }
    finally {
      setLoading(false); // Stop the loader once the data is fetched or an error occurs
    }  
  };
 
  // Close the drawer
  const closeDrawer = () => {
    setShowDrawer(false); // Close the drawer when the close button is clicked
  };
 
  // Function to handle the formatting of numbers
  const formatNumber = (number) => {
    return (number !== undefined && number !== null) ? number.toString().padStart(2, '0') : '00';
  };
 
 
  // if (loading) {
  //   return <div>Loading results...</div>;
  // }
 
 
  // Function to render each option with proper coloring based on whether the user selected it correctly or incorrectly
  const renderOption = (option, selectedOption, correctOption, isCorrect, questionType) => {
    let optionClass = "timedquizresults-unattempted-gray"; // Default class for unattempted options
 
    // If no option is selected by the user (unattempted), only show the correct answer in green
    if (selectedOption === null || selectedOption === "unattempted") {
      optionClass = option === correctOption ? "timedquizresults-correct-green" : "timedquizresults-unattempted-gray";
    } else if (option === selectedOption) {
      // Highlight user's selected option, green if correct, red if incorrect
      optionClass = selectedOption === correctOption ? "timedquizresults-correct-green" : "timedquizresults-incorrect-red";
    } else if (option === correctOption) {
      // If not selected, but this is the correct answer, highlight it in green
      optionClass = "timedquizresults-correct-green";
    }
 
    return (
      <div key={option} className={`timedquizresults-option ${optionClass}`}>
        {questionType === 'multiple_options' ? (
          <input type="checkbox" disabled checked={selectedOption?.includes(option)} />
        ) : (
          <input type="radio" disabled checked={selectedOption === option} />
        )}
        <label className='timedquizoption'>{option}</label>
      </div>
    );
  };

  const handleTonextModuleOrTopic = () => {
    if(location.pathname.includes('/timedquiz-results/')){
      if (currentTopicIndex < topicsIndex?.length - 1) {
        // Get next topic details
        const nextTopic = topicsArrayData[currentTopicIndex + 1];
        const nextTopicId = nextTopic._id;
        const nextTopicTitle = nextTopic.title;
        const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
        const nextMediaContent = nextTopic.media_content_ids[0];
        const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
        const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
        const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;
    
        dispatch(setNotesId(nextNotesId || null));
        dispatch(setVideoId(nextVideoId || null));
        dispatch(setTopicId(nextTopicId || null));
        dispatch(setMediaContentId(nextMediaContentId || null));
        dispatch(setPowerPointId(nextPptId || null));
        dispatch(setTopicTitle(nextTopicTitle || null));
    
        let labId = '', quizId = '',assesmentQuizCheck = '' ,assesmentLabCheck = '';
        nextTopic.question_bank_configs.forEach(config => {
          if (config.type === 'Lab') {
            labId = config._id;
            assesmentLabCheck = config.is_assessment;
          } else if (config.type === 'Quiz') {
            quizId = config._id;
            assesmentQuizCheck = config.is_assessment;
          }
        });
    
        dispatch(setLabConfigId(labId ? labId : null));
        dispatch(setQuizConfigId(quizId ? quizId : null));
        dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
        dispatch(isAssesmentLabCondition(assesmentLabCheck || null));
    
        // Navigate based on available IDs, starting with video
        if (nextVideoId) {
          navigate(`/combine/${courseId}`);
        } else if (nextNotesId) {
          navigate(`/notes/${courseId}`);
        } else if (quizId) {
          navigate(assesmentQuizCheck ?  `/timedquiz/${courseId}` : `/quiz/${courseId}`);
        } else if (labId) {
          navigate(`/lab-list/${courseId}`);
        } else {
          // If none of the content is found, move to the next topic
          handleTonextModuleOrTopic();
        }
      } else if (currentTopicIndex === topicsIndex?.length - 1 && currentModuleIndex < modulesIndex?.length - 1) {
        // Move to the next module if the current topic index is exhausted
        const nextModule = modulesArrayData[currentModuleIndex + 1];
        const nextModuleId = nextModule._id;
        const nextModuleTitle = nextModule.title;
        const nextTopic = nextModule.topics[0];
        const nextTopicId = nextTopic._id;
        const nextTopicTitle = nextTopic.title;
        const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
        const nextMediaContent = nextTopic.media_content_ids[0];
        const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
        const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
        const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;
    
        dispatch(setModuleId(nextModuleId || null));
        dispatch(setModuleTitle(nextModuleTitle || null));
        dispatch(setNotesId(nextNotesId || null));
        dispatch(setVideoId(nextVideoId || null));
        dispatch(setTopicId(nextTopicId || null));
        dispatch(setMediaContentId(nextMediaContentId || null));
        dispatch(setPowerPointId(nextPptId || null));
        dispatch(setTopicTitle(nextTopicTitle || null));
    
        let labId = '', quizId = '',assesmentQuizCheck = '' ,assesmentLabCheck = '';
        nextTopic.question_bank_configs.forEach(config => {
          if (config.type === 'Lab') {
            labId = config._id;
            assesmentLabCheck = config.is_assessment;
          } else if (config.type === 'Quiz') {
            quizId = config._id;
            assesmentQuizCheck = config.is_assessment;
          }
        });
    
        dispatch(setLabConfigId(labId ? labId : null));
        dispatch(setQuizConfigId(quizId ? quizId : null));
        dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
        dispatch(isAssesmentLabCondition(assesmentLabCheck || null));
    
        // Navigate based on available IDs for the new module's first topic
        if (nextVideoId) {
          navigate(`/combine/${courseId}`);
        } else if (nextNotesId) {
          navigate(`/notes/${courseId}`);
        } else if (quizId) {
          navigate(assesmentQuizCheck ?  `/timedquiz/${courseId}` : `/quiz/${courseId}`);
        } else if (labId) {
          navigate(`/lab-list/${courseId}`);
        }
      }
    }
  }
 
 
  return (
    <div className="results-body">
      <div className="results-container">
        <div>
          <h3 className="results-quiz">Quiz Results</h3>
          <div className="results-loader">
          <svg width="300" height="300">
             <circle
                className="results-circle-bg"
                cx="150"
                cy="150"
                r={radius}
                strokeDasharray={`${circumference - gap} ${gap}`}
                strokeDashoffset="0"
                strokeLinecap="round"
                transform="rotate(-90 150 150)"
              />
              <circle
                className="results-circle"
                cx="150"
                cy="150"
                r={radius}
                strokeDasharray={`${animatedFillLength} ${circumference - animatedFillLength} ${gap}`}
                strokeDashoffset="0"
                strokeLinecap="round"
                transform="rotate(-90 150 150)"
              />
              <line x1="110" y1="170" x2="150" y2="210" stroke="#000080" strokeWidth="5" strokeLinecap="round" />
              <line x1="150" y1="210" x2="210" y2="120" stroke="#000080" strokeWidth="5" strokeLinecap="round" />
            </svg>
            <div className="results-score-text">
              Your score: {Math.round((quizResults.correct_answers / quizResults.total_questions) * 100)}
            </div>
            <div className="total-score-text">
              Total Score<br />100
            </div>
          </div>
        </div>
 
        <div>
          {quizResults.correctAnswers >= 5 ? (
            <div style={{ paddingTop: '20px', height: '250px', backgroundImage: `url(${gif_one})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <h1 className="quiz-results-cong-text">Congratulations!</h1>
              <p className="quiz-results-cong-msg">Great job! You have completed the quiz successfully.</p>
            </div>
          ) : (
            <div style={{ paddingTop: '20px', height: '250px' }}>
              <h1 className="quiz-results-cong-text">Oops!</h1>
              <p className="quiz-results-cong-msg">Better luck next time.</p>
            </div>
          )}
 
          <div className="results-details-container">
            <ul className="results-score-details">
              <li className="results-questions">
                Number of questions: <span>{formatNumber(quizResults.total_questions)}</span>
              </li>
              <li className="results-unattempted">
                Unattempted: <span>{formatNumber(quizResults.unattempted_answers)}</span>
              </li>
              <li className="results-correct">
                Correct: <span>{formatNumber(quizResults.correct_answers)}</span>
              </li>
              <li className="results-incorrect">
                Incorrect: <span>{formatNumber(quizResults.incorrect_answers)}</span>
              </li>
            </ul>
 
            <div className="results-buttonstimed-asessment">
              <button className="reattempt-buttontimed-asessment" onClick={handleReview}>Review</button>
              <button className="continue-button" onClick={handleTonextModuleOrTopic}> Next Module</button>
            </div>
          </div>
        </div>
      </div>
 
      {/* Review Drawer */}
      <div className={`review-drawer ${showDrawer ? 'open' : ''}`}>
      <div className="drawer-header">
  {/* <div className='drawer-arrow-back-btn'>
  <img src={arrowleft} onClick={closeDrawer} alt='arrow-button' />
  <button className="close-drawer-back-btn" onClick={closeDrawer}>Back</button>
  </div> */}
   <div className='drawer-arrow-back-btn'>
            <img src={arrowleft} onClick={closeDrawer} alt='arrow-button' className='drawer-arrow-button'/>
            <button className="close-drawer-back-btn" onClick={closeDrawer}>Back</button>
          </div>
  <button className="close-drawer-btn" onClick={closeDrawer}>X</button>
</div>

        <div className="review-content">
        {loading ? (
      <Animations/>/* Show loading text while fetching data */
    ) : (
  reviewData && reviewData.length > 0 ? (
    reviewData.map((reviewItem, index) => (
      <div key={reviewItem.question_id} className="review-item">
        <p><strong>Q{index + 1}: {reviewItem.question_text}</strong></p>
 
        {/* Ensure all options for the question, if available */}
        {reviewItem.options_text && reviewItem.options_text.length > 0 ? (
          reviewItem.options_text.map((option, optIndex) => (
            renderOption(option, reviewItem.selected_option, reviewItem.correct_option, reviewItem.is_correct, reviewItem.question_type)
          ))
        ) : (
          <p>No options available for this question.</p>
        )}
 
        {/* Display if the question was unattempted */}
        {(!reviewItem.selected_option || reviewItem.selected_option === "unattempted") && (
          <div className="timedquizresults-unattempted-message">
            You haven’t attempted this question.
          </div>
        )}
 
        {/* Explanation for the current question */}
        <p className='timedquizresults-unattempted'><strong>Explanation:</strong> {reviewItem.explanation}</p>
        <hr />
      </div>
    ))
  ) : (
    <p>No review data available.</p>
  )
  )}
</div>
 
      </div>
    </div>
  );
};
 
export default TimedQuizResults;
