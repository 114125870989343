// import React, { useState, useEffect } from "react";
// import course_img from "../assets/Images/coursedCardImg.png";
// import studentsImg from "../assets/Images/profile-2user.png";
// import MentorImg from "../assets/Images/profile_mentor.png";
// import timerImg from "../assets/Images/stopwatchTimer.png";
// import beginerImg from "../assets/Images/firstline.png";
// import { Card, CardHeader, CardBody } from "reactstrap";
// import "../assets/Css/CourseList.css";
// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { Oval } from 'react-loader-spinner';
// import gridIcon from '../assets/Images/dashboard.png';
// import rowIcon from '../assets/Images/list.svg';
// import filter from '../assets/Images/filter-search-one.png';
// import order from '../assets/Images/courseListOrderOne.png';
// import { adminaxios } from "../axios";
// import Tooltip from '@mui/material/Tooltip';
// import CourseSkeletonLoader from "../CourseList/CourseSkeletonLoader";
// import { setCourseTitle, setCourseId } from "../actions/apisIdsActions";
// import LocalStorageCourseDetails from "./LocalStorageCourseDetails";
// import Pagination from '@mui/material/Pagination';
// import { toggleFooterRedux } from "../actions/footerActions";
// import Footer from '../Footer/footer';
// import { courseType } from "../actions/typeOfCourseActions";
// // import { useSelector } from "react-redux";


// const CourseList = () => {
//   const [courses, setCourses] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [view, setView] = useState("grid");
//   const [activeTab, setActiveTab] = useState("skillbased");
//   const [currentCourseId, setCurrentCourseId] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const basicSidebarToggle = useSelector(state => state.basicSidebar.basicSideToggle);
//   const coursesPerPage = 2;
//   const searchQuery = useSelector((state) => state.search.query);



//   // Fetch data based on the active tab
//   useEffect(() => {
//     const fetchCourses = () => {
//       const endpoint = activeTab === "skillbased" ? '/skill_based_courses/' : '/role_based_courses/';
//       adminaxios.get(endpoint)
//         .then(response => {
//           const filteredData = response.data.map(course => ({
//             _id: course._id,
//             course_title: course.course_title,
//             difficulty_level: course.difficulty_level,
//           }));
//           setCourses(filteredData);
//           setLoading(false);
//         })
//         .catch(error => {
//           console.error('Error fetching data:', error);
//           setLoading(false);
//         });
//     };
//     fetchCourses();
//   }, [activeTab]);

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   const handleToCourseRender = (courseId, courseTitle) => {
//     localStorage.setItem('localStorageCourseId', courseId);
//     localStorage.setItem('localStorageCourseTitle', courseTitle);
//     dispatch(setCourseId(courseId));
//     setCurrentCourseId(courseId);
//     dispatch(setCourseTitle(courseTitle));
//     setTimeout(() => {
//       navigate(activeTab === "skillbased" ? `/course-details/${courseId}` : `/rolebased-details/${courseId}`);
//     }, 500);
//   };


//   const CourseCard = ({ course }) => {
//     return (
//       <div>
//         <Card className="course-list-course-card" onClick={() => handleToCourseRender(course._id, course.course_title)}>
//           <CardHeader style={{ padding: '8px' }}>
//             <img
//               src={course_img}
//               alt="Course"
//               style={{
//                 width: "100%",
//                 height: "122px",
//                 objectFit: "cover",
//                 borderRadius: '10px',
//               }}
//             />
//           </CardHeader>

//           <CardBody style={{ padding: "2px 11px 7px 11px" }}>
//             <h1 className="cousre_grid_veiw_card_header">{course.course_title}</h1>
//             <div className="gridveiw_metor_difficulty_alignmnet">
//               <div class="cardgrid_mentor-container">
//                 <img src={MentorImg} alt="Mentor Image" class="cardgrid_mentor-image" />
//                 <div class="cardgrid_mentor-info">
//                   <p class="cardgrid_mentor-name">DataWorks</p>
//                   <p class="cardgrid_mentor-title">Mentor</p>
//                 </div>
//               </div>
//               <div class="cardgrid_level-container">
//                 <img src={beginerImg} alt="Beginner Image" class="cardgrid_level-image" />
//                 <div class="cardgrid_level-info">
//                   <p class="cardgrid_level-heading">{course.difficulty_level}</p>
//                   <p class="cardgrid_level-difficulty">Level</p>
//                 </div>
//               </div>
//             </div>
//             <div className="gridveiw_metor_difficulty_alignment2">
//               <div class="cardgrid_learners-container">
//                 <img src={studentsImg} alt="Learner Image" class="cardgrid_learners-image" />
//                 <div class="cardgrid_learners-info">
//                   <p class="cardgrid_learners-heading">Learners</p>
//                   <p class="cardgrid_learners-count">80+</p>
//                 </div>
//               </div>
//               <div className="cardgrid_completion-container">
//                 <img src={timerImg} alt="Timer Icon" className="cardgrid_completion-image" />
//                 <div className="cardgrid_status-row">
//                   <p className="cardgrid_completion-heading">Status</p>
//                   <div className="cardgrid_progress-bar">
//                     <div
//                       className="cardgrid_progress-fill"
//                       style={{ width: '70%' }} // Adjust the progress percentage here
//                     ></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </CardBody>
//         </Card>

//       </div>

//     );
//   };

//   const CourseRow = ({ course }) => {
//     return (
//       <div className="course-list-course-row"
//         onClick={() => handleToCourseRender(course._id, course.course_title)} >
//         <img src={course_img} alt="Course" className="course-row-img" />
//         <div className="course-row-content">
//           <h5 className="course-row-title">{course.course_title}</h5>
//           <div className="course-row-header">
//             <div className="course-row-left">
//               <div className="course-row-mentor">
//                 <img src={MentorImg} alt="Mentor" className="course-row-mentor-img" />
//                 <div>
//                   <p className="course-row-mentor-name">DataWorks</p>
//                   <p className="course-row-mentor-title">Mentor</p>
//                 </div>
//               </div>
//               <div className="course-row-level">
//                 <img src={beginerImg} alt="beginer" className="course-row-level-img" />
//                 <div >
//                   <p class="cardgrid_level-heading">{course.difficulty_level}</p>
//                   <p class="cardgrid_level-difficulty">Level</p>
//                 </div>
//               </div>
//               <div className="course-row-status">
//                 <img src={timerImg} alt="timer" className="course-row-status-img" />
//                 <p className="course-row-status-text">
//                   Status<span className="course-row-status-percent">70%</span>
//                 </p>
//                 <progress
//                   value={course.progress === 0 ? 70 : 0}
//                   max="100"
//                   className="course-row-progress"
//                 ></progress>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     );
//   };
//   const toggleView = (newView) => {
//     setView(newView);
//   };

//   const toggle = (tab) => {
//     if (activeTab !== tab) {
//       setActiveTab(tab);
//       setLoading(true);  // Set loading to true when switching tabs

//       // Check the tab and dispatch the action accordingly
//       if (tab === 'skillbased') {
//         dispatch(courseType(false)); // If the tab is '2', set courseType to false
//       } else if (tab === 'rolebased') {
//         dispatch(courseType(true));  // For other tabs, set courseType to true
//       }

//       console.log(tab, 'tab');
//     }
//   };


//   const courseTypeToggle = useSelector((state) => state.CourseType.courseType);
//   console.log(courseTypeToggle, 'which_course');
//   const indexOfLastCourse = currentPage * coursesPerPage;
//   const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
//   const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

//   // if (loading) {const CourseList = () => {
  


//   return (
//     <>
//       {currentCourseId && (
//         <LocalStorageCourseDetails
//           courseId={currentCourseId}
//           onCompleted={() => setCurrentCourseId(null)}
//         />
//       )}
//       <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", height: '90vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'thin' }}>
//         {/* <Sidebar /> */}
//         <div id="course-List-main-container" style={{ width: "100%", margin: "10px 0px 0px 0px", display: "flex", flexDirection: "column", padding: '0px 20px' }}>
//           <div id="course-list-container-id">
//             <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", marginTop: "10px", marginLeft: '30px' }}>
//               <div className="toggle-btn-course-list-container">
//                 <button className={`toggle-btn-course-list-component ${activeTab === "skillbased" ? "active" : ""}`} onClick={() => toggle("skillbased")}>Skill based</button>
//                 <button className={`toggle-btn-course-list-component ${activeTab === "rolebased" ? "active" : ""}`} onClick={() => toggle("rolebased")}>Role based</button>

//               </div>
//               <div style={{ display: "flex", marginRight: "7px", alignItems: "center" }}>
//                 <div style={{ display: "flex", marginLeft: "26px", padding: "2px 3px", borderRadius: "90px", backgroundColor: "#F6F6FB", border: "solid 1px #EEEEEE" }}>
//                   <button onClick={() => toggleView("grid")} className={`toggle-view-btn ${view === "grid" ? "active" : ""}`}><img src={gridIcon} alt="Grid View" /></button>
//                   <button onClick={() => toggleView("row")} className={`toggle-view-btn ${view === "row" ? "active" : ""}`}><img src={rowIcon} alt="Row View" /></button>
//                 </div>
//                 <div className="course-list-icons">
//                   <Tooltip title="Filter" placement="top" arrow>
//                     <img src={filter} alt="icon" className="course-list-icon" />
//                   </Tooltip>
//                   <Tooltip title="Order" placement="top" arrow>
//                     <img src={order} alt="icon" className="course-list-icon" />
//                   </Tooltip>
//                 </div>
//               </div>
//             </div>
//             <div>
//               {loading ? (
//                 <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: view === "grid" ? "row" : "column", marginLeft: '30px' }}>
//                   {/* Render skeleton loaders */}
//                   {[...Array(coursesPerPage)].map((_, index) => (
//                     <CourseSkeletonLoader key={index} />
//                   ))}
//                 </div>
//               ) : activeTab === "rolebased" ? (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexWrap: "wrap",
//                     justifyContent: "flex-start",
//                     flexDirection: view === "grid" ? "row" : "column",
//                     marginLeft: "30px",
//                   }}
//                 >
//                   {currentCourses.map((course) =>
//                     view === "grid" ? <CourseCard key={course._id} course={course} /> : <CourseRow key={course._id} course={course} />
//                   )}
//                 </div>
//               ) : (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexWrap: "wrap",
//                     justifyContent: "flex-start",
//                     flexDirection: view === "grid" ? "row" : "column",
//                     marginLeft: "30px",
//                   }}
//                 >
//                   {currentCourses.map((course) =>
//                     view === "grid" ? <CourseCard key={course._id} course={course} /> : <CourseRow key={course._id} course={course} />
//                   )}
//                 </div>
//               )}
//             </div>

//             {courses.length > 10 && (
//               <div className="pagination-container">
//                 <Pagination
//                   count={Math.ceil(courses.length / coursesPerPage)}
//                   page={currentPage}
//                   onChange={handlePageChange}
//                   color="primary"
//                   sx={{ marginTop: '10px', marginBottom: '40px', alignSelf: 'flex-end' }}
//                 />
//               </div>
//             )}
//           </div>
//           <div className="Course-list-footer">
//             <Footer />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CourseList;






















import React, { useState, useEffect } from "react";
import course_img from "../assets/Images/coursedCardImg.png";
import studentsImg from "../assets/Images/profile-2user.png";
import MentorImg from "../assets/Images/profile_mentor.png";
import timerImg from "../assets/Images/stopwatchTimer.png";
import beginerImg from "../assets/Images/firstline.png";
import { Card, CardHeader, CardBody } from "reactstrap";
import "../assets/Css/CourseList.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Oval } from 'react-loader-spinner';
import gridIcon from '../assets/Images/dashboard.png';
import rowIcon from '../assets/Images/list.svg';
import filter from '../assets/Images/filter-search-one.png';
import order from '../assets/Images/courseListOrderOne.png';
import { adminaxios } from "../axios";
import Tooltip from '@mui/material/Tooltip';
import CourseSkeletonLoader from "../CourseList/CourseSkeletonLoader";
import { setCourseTitle, setCourseId } from "../actions/apisIdsActions";
import LocalStorageCourseDetails from "./LocalStorageCourseDetails";
import Pagination from '@mui/material/Pagination';
import { toggleFooterRedux } from "../actions/footerActions";
import Footer from '../Footer/footer';
import { courseType } from "../actions/typeOfCourseActions";

// Search query selector
const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState("grid");
  const [activeTab, setActiveTab] = useState("skillbased");
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicSidebarToggle = useSelector(state => state.basicSidebar.basicSideToggle);
  const courseTypeToggle = useSelector((state)=>state.CourseType.courseType);

  const coursesPerPage = 2;

  // Get search query from Redux store
  const searchQuery = useSelector((state) => state.HeaderSearchbar.query);

  // Fetch data based on the active tab
  useEffect(() => {
    const fetchCourses = () => {
      const endpoint = activeTab === "skillbased" ? '/skill_based_courses/' : '/role_based_courses/';
      adminaxios.get(endpoint)
        .then(response => {
          const filteredData = response.data.map(course => ({
            _id: course._id,
            course_title: course.course_title,
            difficulty_level: course.difficulty_level,
          }));
          setCourses(filteredData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    };
    fetchCourses();
  }, [activeTab]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleToCourseRender = (courseId, courseTitle) => {
    localStorage.setItem('localStorageCourseId', courseId);
    localStorage.setItem('localStorageCourseTitle', courseTitle);
    dispatch(setCourseId(courseId));
    setCurrentCourseId(courseId);
    dispatch(setCourseTitle(courseTitle));
    setTimeout(() => {
      navigate(activeTab === "skillbased" ? `/course-details/${courseId}` : `/rolebased-details/${courseId}`);
    }, 500);
  };

  const CourseCard = ({ course }) => {
    return (
      <div>
        <Card className="course-list-course-card" onClick={() => handleToCourseRender(course._id, course.course_title)}>
          <CardHeader style={{ padding: '8px' }}>
            <img
              src={course_img}
              alt="Course"
              style={{
                width: "100%",
                height: "122px",
                objectFit: "cover",
                borderRadius: '10px',
              }}
            />
          </CardHeader>

          <CardBody style={{ padding: "2px 11px 7px 11px" }}>
            <h1 className="cousre_grid_veiw_card_header">{course.course_title}</h1>
            <div className="gridveiw_metor_difficulty_alignmnet">
              <div className="cardgrid_mentor-container">
                <img src={MentorImg} alt="Mentor Image" className="cardgrid_mentor-image" />
                <div className="cardgrid_mentor-info">
                  <p className="cardgrid_mentor-name">DataWorks</p>
                  <p className="cardgrid_mentor-title">Mentor</p>
                </div>
              </div>
              <div className="cardgrid_level-container">
                <img src={beginerImg} alt="Beginner Image" className="cardgrid_level-image" />
                <div className="cardgrid_level-info">
                  <p className="cardgrid_level-heading">{course.difficulty_level}</p>
                  <p className="cardgrid_level-difficulty">Level</p>
                </div>
              </div>
            </div>
            <div className="gridveiw_metor_difficulty_alignment2">
              <div className="cardgrid_learners-container">
                <img src={studentsImg} alt="Learner Image" className="cardgrid_learners-image" />
                <div className="cardgrid_learners-info">
                  <p className="cardgrid_learners-heading">Learners</p>
                  <p className="cardgrid_learners-count">80+</p>
                </div>
              </div>
              <div className="cardgrid_completion-container">
                <img src={timerImg} alt="Timer Icon" className="cardgrid_completion-image" />
                <div className="cardgrid_status-row">
                  <p className="cardgrid_completion-heading">Status</p>
                  <div className="cardgrid_progress-bar">
                    <div
                      className="cardgrid_progress-fill"
                      style={{ width: '70%' }} // Adjust the progress percentage here
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  const CourseRow = ({ course }) => {
    return (
      <div className="course-list-course-row"
        onClick={() => handleToCourseRender(course._id, course.course_title)}>
        <img src={course_img} alt="Course" className="course-row-img" />
        <div className="course-row-content">
          <h5 className="course-row-title">{course.course_title}</h5>
          <div className="course-row-header">
            <div className="course-row-left">
              <div className="course-row-mentor">
                <img src={MentorImg} alt="Mentor" className="course-row-mentor-img" />
                <div>
                  <p className="course-row-mentor-name">DataWorks</p>
                  <p className="course-row-mentor-title">Mentor</p>
                </div>
              </div>
              <div className="course-row-level">
                <img src={beginerImg} alt="Beginner" className="course-row-level-img" />
                <div>
                  <p className="cardgrid_level-heading">{course.difficulty_level}</p>
                  <p className="cardgrid_level-difficulty">Level</p>
                </div>
              </div>
              <div className="course-row-status">
                <img src={timerImg} alt="Timer" className="course-row-status-img" />
                <p className="course-row-status-text">
                  Status<span className="course-row-status-percent">70%</span>
                </p>
                <progress
                  value={course.progress === 0 ? 70 : 0}
                  max="100"
                  className="course-row-progress"
                ></progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleView = (newView) => {
    setView(newView);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setLoading(true);  // Set loading to true when switching tabs

      // Check the tab and dispatch the action accordingly
      if (tab === 'skillbased') {
        dispatch(courseType(false)); // If the tab is 'skillbased', set courseType to false
      } else if (tab === 'rolebased') {
        dispatch(courseType(true));  // If the tab is 'rolebased', set courseType to true
      }

      console.log(tab, 'tab');
    }
  };

 
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  
  // Filter the courses based on the search query before applying pagination
  const filteredCourses = courses.filter((course) =>
    course.course_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Apply pagination after filtering the courses
  const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);

  return (
    <>
      {currentCourseId && (
        <LocalStorageCourseDetails
          courseId={currentCourseId}
          onCompleted={() => setCurrentCourseId(null)}
        />
      )}
      <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", height: '90vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'thin' }}>
        {/* <Sidebar /> */}
        <div id="course-List-main-container" style={{ width: "100%", margin: "10px 0px 0px 0px", display: "flex", flexDirection: "column", padding: '0px 20px' }}>
          <div id="course-list-container-id">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", marginTop: "10px", marginLeft: '30px' }}>
              <div className="toggle-btn-course-list-container">
                <button className={`toggle-btn-course-list-component ${activeTab === "skillbased" ? "active" : ""}`} onClick={() => toggle("skillbased")}>Skill based</button>
                <button className={`toggle-btn-course-list-component ${activeTab === "rolebased" ? "active" : ""}`} onClick={() => toggle("rolebased")}>Role based</button>
              </div>
              <div style={{ display: "flex", marginRight: "7px", alignItems: "center" }}>
                <div style={{ display: "flex", marginLeft: "26px", padding: "2px 3px", borderRadius: "90px", backgroundColor: "#F6F6FB", border: "solid 1px #EEEEEE" }}>
                  <button onClick={() => toggleView("grid")} className={`toggle-view-btn ${view === "grid" ? "active" : ""}`}><img src={gridIcon} alt="Grid View" /></button>
                  <button onClick={() => toggleView("row")} className={`toggle-view-btn ${view === "row" ? "active" : ""}`}><img src={rowIcon} alt="Row View" /></button>
                </div>
                <div className="course-list-icons">
                  <Tooltip title="Filter" placement="top" arrow>
                    <img src={filter} alt="icon" className="course-list-icon" />
                  </Tooltip>
                  <Tooltip title="Order" placement="top" arrow>
                    <img src={order} alt="icon" className="course-list-icon" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div>
              {loading ? (
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: view === "grid" ? "row" : "column", marginLeft: '30px' }}>
                  {/* Render skeleton loaders */}
                  {[...Array(coursesPerPage)].map((_, index) => (
                    <CourseSkeletonLoader key={index} />
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    flexDirection: view === "grid" ? "row" : "column",
                    marginLeft: "30px",
                  }}
                >
                  {currentCourses.map((course) =>
                    view === "grid" ? <CourseCard key={course._id} course={course} /> : <CourseRow key={course._id} course={course} />
                  )}
                </div>
              )}
            </div>

            {filteredCourses.length > coursesPerPage && (
              <div className="pagination-container">
                <Pagination
                  count={Math.ceil(filteredCourses.length / coursesPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{ marginTop: '10px', marginBottom: '40px', alignSelf: 'flex-end' }}
                />
              </div>
            )}
          </div>
          <div className="Course-list-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseList;
