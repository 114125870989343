import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const Image = styled('img')({
  width: '100%',
});

function SkeletonCard() {
  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ margin: 1 }}>
          <Skeleton variant="circular" width={40} height={40}>
            <Avatar />
          </Skeleton>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Skeleton variant="rectangular" width="80%" height={120}>
        <div style={{ paddingTop: '30%' }} />
      </Skeleton>
    </div>
  );
}

export default function TestimonialSkeleton() {
  return (
    <Grid container spacing={4}>
      {/* Three skeletons in one row */}
      <Grid item xs={12} md={4}>
        <SkeletonCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <SkeletonCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <SkeletonCard />
      </Grid>
    </Grid>
  );
}
