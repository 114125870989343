import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const publicRoute = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('tokens');

    useEffect(() => {
      if (token) {
        navigate('/course-list');
      }
    }, [token, navigate]);

    return !token ? <WrappedComponent {...props} /> : null; 
  };
};

export default publicRoute;
