import React, { useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { adminaxios } from '../axios';
import { useLocation } from 'react-router-dom';

import {
    setCourseId, 
    setModuleId, 
    setTopicId, 
    setMediaContentId, 
    setLabConfigId, 
    setQuizConfigId, 
    setCourseTitle, 
    setModuleTitle, 
    setTopicTitle ,
    setNotesId,
    setVideoId,
    setPowerPointId,
    isAssesmentQuizCondition,
    isAssesmentLabCondition
} from '../actions/apisIdsActions';


const LocalStorageCourseDetails = ({ courseId }) => {
    const location = useLocation()
    const roleBasedCourseID = localStorage.getItem('localStorageCourseId');
    const rolebasedTwo = localStorage.getItem('role_based_courseId');
    const courseTypeToggle = useSelector((state)=>state.CourseType.courseType);
    console.log('cours-type',courseTypeToggle);
    const dispatch = useDispatch();
    //dispatch(setCourseId(courseId));
    if(courseTypeToggle){
        dispatch(setCourseId(roleBasedCourseID));
    }else{
        dispatch(setCourseId(courseId));
    }
    const activeRolebasedId = courseTypeToggle ? roleBasedCourseID : courseId ;
   console.log('activeCou',activeRolebasedId);
    
    useEffect(() => {
        console.log('Received courseId:', courseId);
        adminaxios.get(`/courses/${activeRolebasedId}/`)
            .then(response => {
                console.log('API response:', response.data);
                const { data } = response.data;
                const firstModule = data.module_details[0];
                const firstTopic = firstModule.topics[0];
                const labConfig = firstTopic.question_bank_configs.find(config => config.type === "Lab");
                const quizConfig = firstTopic.question_bank_configs.find(config => config.type === "Quiz");

              

                const labId = labConfig ? labConfig._id : null;
                const quizId = quizConfig ? quizConfig._id : null;

                const quizAssesment = quizConfig ? quizConfig.is_assessment : null 
                const labAssesment = labConfig ? labConfig.is_assessment : null 


                const htmlContent = firstTopic.media_content_ids
                .flatMap(media => media.content_ids)
                .find(content => content.format === 'HTML');

                const mp4Content = firstTopic.media_content_ids
                .flatMap(media => media.content_ids)
                .find(content => content.format === '.mp4');


        
                const PPtContent = firstTopic.media_content_ids
                .flatMap(media => media.content_ids)
                .find(content => content.format === '.pptx');

                const htmlId  = htmlContent ? htmlContent.content_id : null ;
                const videoId = mp4Content ? mp4Content.content_id : null ;
                const PptId =  PPtContent ? PPtContent.content_id : null
                

                // Store in localStorage
                localStorage.setItem('localStorageCourseId',courseTypeToggle? roleBasedCourseID : courseId);
                localStorage.setItem('LocalModuleId', firstModule._id);
                localStorage.setItem('LocalTopicId', firstTopic._id);
                localStorage.setItem('LocalMediaContentId', firstTopic.media_content_ids[0]._id);
                localStorage.setItem('LocalLabId', labId);
                localStorage.setItem('LocalQuizId', quizId);
                localStorage.setItem('ModuleName', firstModule.title);
                localStorage.setItem('TopicName', firstTopic.title);
                localStorage.setItem('HtmlId', htmlId);
                localStorage.setItem('VideoId',videoId);
                localStorage.setItem('PPT_id', PptId)
                localStorage.setItem('Quiz_assesment',quizAssesment);
                localStorage.setItem('Lab_assesment',labAssesment);


                // Dispatch Redux actions to update the state
                dispatch(setModuleId(firstModule._id));
                dispatch(setTopicId(firstTopic._id));
                dispatch(setMediaContentId(firstTopic.media_content_ids[0]._id));
                dispatch(setLabConfigId(labId));
                dispatch(setQuizConfigId(quizId));
                dispatch(setModuleTitle(firstModule.title));
                dispatch(setTopicTitle(firstTopic.title));
                dispatch(setNotesId(htmlId));
                dispatch(setVideoId(videoId));
                dispatch(setPowerPointId(PptId));
                dispatch(isAssesmentLabCondition(labAssesment));
                dispatch(isAssesmentQuizCondition(quizAssesment));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [courseId, dispatch,roleBasedCourseID]); // Ensure useEffect runs when courseId changes

    return null; // or any UI you want to show during data fetching
};

export default LocalStorageCourseDetails;
