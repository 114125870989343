import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import withAuth from './Authentication/WithAuth';
import publicRoute from './Authentication/PublicRoute';
import Ppt from './CourseRendering/Ppt';
import Video from './CourseRendering/Video';
import Combine from './CourseRendering/Combine';
import Quiz from './Quiz/Quiz';
import QuizPage from './Quiz/QuizPage';
import QuizResults from './Quiz/QuizResults';
import Header from './Header/Header';
import Header1 from './Header/Header1';
import CodeEditor from './ProblemSolving/CodeEditor';
import CourseList from './CourseList/CourseList';
import Login from './Authentication/Signin';
import ProfileSettings from './Settings/ProfileSettings';
import PrivacySettings from './Settings/PrivacySettings';
import Signup from './Authentication/Signup';
import VerifyEmail from './Authentication/VerifyEmail';
import OtpConfirm from './Authentication/OtpConfirm';
import ForgotPassword from './Authentication/ForgotPassword';
import ChangePassword from './Authentication/ChangePassword';
import SetNewPassword from './Authentication/SetNewPassword';
import SuccessMessage from './Authentication/SuccessMessage';
import ContentHome from './ContentManagement/ContentHome';
import HeaderAssessment from './Header/HeaderAssessment';
import TimedLab from './ProblemSolving/TimedLab';
import LabLists from './ProblemSolving/LabLists';
import HeaderForQuizPage from './Header/HeaderForQuizPage';
import Notes from './Notes/notes';
import CourseDetails from './CourseList/CourseDetails/CourseDetails';
import RolebasedDetails from './CourseList/RolebasedDetails/RolebasedDetails';
import Sample_iframe from './ProblemSolving/Sample_iframe';
import Learning from './Learning/Learning';
import DashBoard from './DashBoard/Dashboard';
import Users from './Users/Users';
import Repots from './Reports/Reports';
import Enrollment from './Enrollment/Enrollment' ;
import ModuleDetailsDisplay from './CourseRendering/ModuleDetailsDisplay';
import TimedQuiz from './TimedQuiz/TimedQuiz';
import TimedQuizPage from './TimedQuiz/TimedQuizPage';
import TimedQuizResults from './TimedQuiz/TimedQuizResults';
import  TimedAssessment from './ProblemSolving/TimedAssessment';
import Header2 from './Header/Header2';
// import Footer from './Footer/footer';
import Challenges from './Challenges/Challenges';
import Sidebar2 from './Sidebar/Sidebar2';



import './App.css';

const ProtectedCourseList = withAuth(CourseList);
const ProtectedLoginPage = publicRoute(Login);

function Layout() {
  const location = useLocation();
  const { id } = useParams(); 
  const appSidebarCourseId = useSelector((state) => state.dynamicApiId.courseId);
  const appSidebarCourseIdNew = localStorage.getItem('localStorageCourseId');
  console.log(appSidebarCourseId, 'app-course');



  let HeaderComponent = Header1; // Default to Header1
 
  // Determine which header to use
  if (
   
    location.pathname === '/change-password' ||
    location.pathname === '/profile-settings' ||
    location.pathname === '/privacy-settings' ||
    location.pathname === '/learning' ||
    location.pathname === '/challenges' 
   
   
  ) {
    HeaderComponent = Header;
  } else if (location.pathname === '/course-list'){
    HeaderComponent = Header;
  }
  else if (matchPath('/course-details/:id', location.pathname) ||  matchPath('/rolebased-details/:id', location.pathname)) {
    HeaderComponent = Header2; // Render "Header2" for course details page
  }
  else if (
    matchPath('/lab/:id', location.pathname) ||
    matchPath('/timed-assessment-lab/:id', location.pathname) ||
    location.pathname === '/' ||
    location.pathname === '/signup' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/otp-confirm' ||
    location.pathname === '/set-new-password' ||
    location.pathname === '/success-message' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/timed-lab' ||
    location.pathname === '/timedquiz' ||
    location.pathname === '/timedquiz-results'

  
  ) {
    HeaderComponent = null; // Don't show any header on these pages
  } else if ( matchPath('/timedquiz-page/:id', location.pathname))
    {
    HeaderComponent = HeaderAssessment;
  }

  let SidebarComponent = false ;

  if (matchPath('/combine/:id',location.pathname) || 
  matchPath('/notes/:id',location.pathname) || 
  matchPath('/quiz/:id',location.pathname) || 
  matchPath('/lab-list/:id',location.pathname)

){
SidebarComponent = true
}

  
  // Determine if footer should be displayed on certain routes
  const showFooter = location.pathname === '/course-list' || matchPath('/course-details/:id', location.pathname);
 
  return (
    <div>
      {HeaderComponent && <HeaderComponent />}
      <div className='layout-cont-app-js-routes'>
        {SidebarComponent && <Sidebar2 courseId={appSidebarCourseId} />}
        <div className='content-cont-app-js-routes'>
       <Routes>
        <Route path="/" element={<ProtectedLoginPage />} />
        <Route path="/video" element={<Video />} />
        <Route path="/ppt" element={<Ppt />} />
        <Route path="/combine/:id" element={<Combine />} />
        <Route path="/quiz/:id" element={<Quiz />} />
        <Route path="/quiz-page/:id" element={<QuizPage />} />
        <Route path="/quiz-results/:id" element={<QuizResults />} />
        <Route path="/lab/:id" element={<CodeEditor />} />
        <Route path="/course-list" element={<ProtectedCourseList />} />
        <Route path="/profile-settings" element={<ProfileSettings />} />
        <Route path="/privacy-settings" element={<PrivacySettings />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/otp-confirm" element={<OtpConfirm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/success-message" element={<SuccessMessage />} />
        <Route path="/content-management" element={<ContentHome />} />
        <Route path="/timed-lab" element={<TimedLab />} />
        <Route path="/lab-list/:id" element={<LabLists />} />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route path='/rolebased-details/:id' element={<RolebasedDetails />} />
        <Route path='/notes/:id' element={<Notes />} />
        <Route path='/users' element={<Users />} />
        <Route path='/dashboard' element={<DashBoard />} />
        <Route path='/challenges' element={<Challenges />} />
        <Route path='/reports' element={<Repots />} />
        <Route path='/learning' element={<Learning />} />
        <Route path='/enrollment' element={<Enrollment />} />
        <Route path='/module-information/:id' element={<ModuleDetailsDisplay />} />
        <Route path='/timedquiz/:id' element={<TimedQuiz/>} />
        <Route path='/timedquiz-page/:id' element={<TimedQuizPage />} />
        <Route path='/timedquiz-results/:id' element={<TimedQuizResults />} />
        <Route path='/header-assessment' element={<HeaderAssessment/>} />
        <Route path='/timed-assessment-lab/:id' element={<TimedAssessment />} />
      </Routes>
      </div>
      </div>
      {/* {showFooter && <Footer />} */}
    </div>
  );
}

 

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}
 
export default App;
